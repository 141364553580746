.mobile_home_container {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}
.mobile_home_add {
  flex-shrink: 0;
  padding: 20px;
  text-align: right;
  font-size: 24px;
  color: #74bdc6;
}
.mobile_home_main {
  flex: 1;
  overflow: hidden;
}
.mobile_ios_home_add {
  padding: 60px 20px 20px!important;
}