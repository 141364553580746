.modal_container {
    width: 560px;
    height: 494px;
}

.modal_container :global(.ant-modal-content) {
    padding: 0 0 16px 0 !important;
}

.main {
    width: 100%;
    height: 100%;
    padding: 47px 52px 0 52px;
}

.header,
.section {
    margin-bottom: 20px;
}

.header_title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #1F1F1F;
    text-align: center;
}

.header_explain {
    font-size: 14px;
    color: #1f1f1f;
    text-align: center;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.img {
    width: 270px;
    height: 240px;
}

.footer {
    width: 100%;
    height: 48px;
}
