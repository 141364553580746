.add_to_desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    background: url('../../../../statics/add_to_desktop.png') no-repeat center / 100% 100%;
    transition: background 0.5s;
}

.add_to_desktop::before {
    content: "";
    background: url('../../../../statics/add_to_desktop_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.add_to_desktop::after {
    content: "";
    background: url('../../../../statics/add_to_desktop_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.add_to_desktop:hover {
    background: url('../../../../statics/add_to_desktop_hover.png') no-repeat center / 100% 100%;
}

.add_to_desktop:active {
    background: url('../../../../statics/add_to_desktop_active.png') no-repeat center / 100% 100%;
}