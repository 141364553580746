.main {
    width: 100%;
    height: 100%;
}

.custome_upload {
    width: 100%;
    height: 100%;
}

.custome_upload :global(.ant-upload-drag-container) {
    position: relative;
    width: 100%;  /* 或者指定具体的宽度 */
    height: 100%; /* 或者指定具体的高度 */
    overflow: hidden;  /* 确保图片不会溢出 */
}

.image_preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;  /* 使图片按比例缩放并居中 */
}

.upload_text {
    font-size: 20px;
    margin: 5px 0;
}

.min_text {
    margin: 5px 0;
    font-size: 12px;
    color: #aaa;
}
