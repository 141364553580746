.main {
    width: 100%;
    height: 100%;
    cursor: default;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.main::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.main::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.main::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    padding: 40px 0;
    margin: 0 auto;
}

.container_header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 24px;
    color: #101417;
}

.container_banner {
    width: 100%;
    height: 133px;
    border-radius: 12px;
}

.container_divider {
    width: 100%;
    padding-top: 30px;
    border-bottom: 1px solid #CACFDB;
    margin-bottom: 30px;
}

.container_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* height: calc(100% - 100px); */
    height: 805px;
}

.container_content_left,
.container_content_right {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    width: 48%;
    /* height: 100%; */
    height: 100%;
}

.container_content_left_header,
.container_content_right_header {
    width: 100%;
    height: 36px;
}

.container_content_right_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.header_title {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #101417;
}

.container_content_left_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 750px;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    padding: 20px 0;
}

.container_content_right_main {
    width: 100%;
    height: 750px;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.container_content_right_main::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.container_content_right_main::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.container_content_right_main::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.more_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20px;
    cursor: pointer;
    user-select: none;
}

.left_category {
    display: flex;
    width: 100%;
    height: 56px;
    padding: 0 40px 40px;
}

.category_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    border-radius: 6px;
    margin-right: 16px;
    font-size: 16px;
    color: #4f4f4f;
    cursor: pointer;
    user-select: none;
}

.category_butn:hover {
    background-color: #f9f9f9;
}

.category_butn_active,
.category_butn_active:hover {
    background-color: #17a8b5;
    color: #fff;
}

.left_components {
    width: 100%;
    height: calc(100% - 56px);
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.left_components::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.left_components::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.left_components::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.more_text {
    font-size: 14px;
    font-weight: bold;
    color: #17A8B5;
    margin-right: 5px;
}

.more_img {
    width: 12px;
    height: 13px;
}

.shopping_model_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 118px;
    padding: 11px 7px;
    border: 1px solid #D9D9D9;
    margin-bottom: 15px;
    border-radius: 12px;
    background-color: #f9f9f9;
    cursor: pointer;
}

.shopping_model_item:hover,
.shopping_model_item_select {
    border: 1px solid #74BDC6;
}

.shopping_model_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    min-width: 51px;
    height: 51px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background-color: #FFF;
}

.shopping_model_img {
    width: 38px;
    height: 38px;
}

.attached_components_info_box {
    display: flex;
    width: 100%;
    height: 24px;
    margin-bottom: 3px;
}

.attached_components_icon_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 6px;
}

.attached_components_icon_text {
    color: #4f4f4f;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
}

.shopping_model_text_box {
    flex-grow: 1;
    padding: 0 45px 0 14px;
}

.shopping_model_text_box_unowned {
    padding: 0 10px 0 14px;
}

.shopping_model_text_title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
}

.shopping_model_text_info {
    display: -webkit-box;        /* 创建一个弹性盒子 */
    -webkit-box-orient: vertical; /* 设置盒子的子元素排列方式为垂直 */
    -webkit-line-clamp: 2;       /* 限制显示两行 */
    overflow: hidden;            /* 隐藏超出内容 */
    text-overflow: ellipsis;     /* 使用省略号表示超出的文本 */
    color: #414648;
    font-size: 14px;
    line-height: 20px;
}

.shopping_model_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    border-radius: 6px;
    font-size: 11px;
}

.shopping_model_owned {
    width: 83px;
    min-width: 83px;
    color: #74bdc6;
    background-color: #e4f0f2;
    border: 1px solid #74bdc6;
    border-bottom-width: 2px;
}

.shopping_model_purchase {
    width: 128px;
    min-width: 128px;
    color: #EABC4E;
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-bottom-width: 2px;
}

.model_status_img {
    margin-right: 5px;
}

.right_header_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 118px;
    margin: 0 auto;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    margin-top: 25px;
}

.right_header_img {
    width: 100px;
    height: 100px;
}

.right_header_text_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.right_header_text {
    color: #101417;
    font-family: "PingFangSCMedium";
    font-size: 18px;
    font-weight: 700;
    margin: 12px;
}

.introduce_box,
.example_box {
    width: 100%;
    padding: 10px 0;
}

.introduce_title,
.example_title {
    padding: 0 0 5px 10px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 16px;
    color: #101417;
}

.introduce_box .attached_components_info_box {
    margin: 10px 0;
}

.introduce_text,
.example_text {
    width: 100%;
    padding: 13px;
    border: 1px solid #D9D9D9;
    background-color: #f9f9f9;
    border-radius: 12px;
    font-size: 14px;
    line-height: 16px;
    color: #414648;
}

.example_text img {
    max-width: 100%;
}