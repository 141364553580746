.main {
    width: 100%;
    height: 100%;
}

.content {
    padding: 30px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.content::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.content::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.content::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}