.side_arrow_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 22px;
    cursor: pointer;
}

.side_arrow_close {
    width: 4px;
    height: 21px;
}

.side_arrow_open {
    width: 8px;
    height: 22px;
}

:global(.ant-message .ant-message-notice) {
    margin-left: 215px !important;
    transition: margin 0.5s;
}

:global(.ant-message-custom-margin-right .ant-message-notice) {
    margin-left: 0 !important;
    margin-right: 56px !important;
    transition: margin 0.5s;
}
