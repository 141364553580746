.container {
    /* display: flex; */
    margin-top: 11px;
}

.avatar_box_left,
.avatar_box_right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
}

.avatar_box_right {
    justify-content: flex-end;
}

.avatar_title {
    font-size: 12px;
    color: #414648;
    margin: 0 5px;
}

.avatar_img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.ai_avatar_img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.content_box {
    display: flex;
    width: 100%;
}

.content_box_left {
    justify-content: flex-start;
}

.content_box_right {
    justify-content: flex-end;
}

.content_time {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    color: #999;
}

.content_text {
    position: relative;
    display: inline-block;
    padding: 12px;
    position: relative;
}

.content_left {
    background-color: #ffffff;
    border-radius: 6px;
}

.content_left :global(.markdown-body) {
    color: #101417 !important;
    font-size: 12px;
}

.content_right {
    background-color: #ffffff;
    border-radius: 6px;
}

.content_right :global(.markdown-body) {
    color: #323A45 !important;
    font-size: 12px;
}

.content_recommend {
    margin-bottom: 40px;
}

.content_text_butns {
    position: absolute;
    width: 150px;
    height: 32px;
    right: -200px;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
}

.butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #e4f0f2;
}

.butn:hover {
    background-color: #c6e0e4;
}

.butn_img {
    width: 16px;
    height: 16px;
}

.like_img {
    background-image: url('../../../../statics/content_text_like.png');
}

.butn:hover .like_img {
    background-image: url('../../../../statics/content_text_like_hover.png');
}

.negative_img {
    background-image: url('../../../../statics/content_text_negative.png');
}

.butn:hover .negative_img {
    background-image: url('../../../../statics/content_text_negative_hover.png');
}

.regenerate_img {
    background-image: url('../../../../statics/content_text_regenerate.png');
}

.butn:hover .regenerate_img {
    background-image: url('../../../../statics/content_text_regenerate_hover.png');
}

.butn_active,
.butn_active:hover {
    background-color: #17a8b5;
}

.butn_active .like_img,
.butn_active:hover .like_img {
    background-image: url('../../../../statics/content_text_like_active.png');
}

.butn_active .negative_img,
.butn_active:hover .negative_img {
    background-image: url('../../../../statics/content_text_negative_active.png');
}

.loading_img {
    width: 30px;
    height: 10px;
}

.recommend_box {
    position: absolute;
    right: 0;
    bottom: -40px;
    width: 174px;
    height: 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    background-color: #e4f0f2;
    transition: background-color 0.5s ease-in-out;
}

.recommend_box:hover {
    background-image: linear-gradient(to right, #c6e0e4 50%, #e4f0f2 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: background-color 0.5s ease-in-out, background-position 0.5s ease-in-out;
}

.recommend_box:not(:hover) {
    background-position: right bottom;
}

.recommend_butn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.recommend_butn_text {
    font-size: 14px;
    color: #17a8b5;
    margin-right: 10px;
}

.recommend_butn_icon {
    width: 6px;
    height: 12px;
    background: url('../../../../statics/recommend_butn_icon.png') no-repeat center / 100% 100%;
    transition: transform 0.5s ease;
}

.recommend_box:hover .recommend_butn_icon {
    transform: translateX(5px);
}

.popover_box {
    /* padding: 16px; */
}

.popover_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 160px;
    height: 40px;
    padding-left: 16px;
    padding-bottom: 3px;
    margin-bottom: 3px;
    cursor: pointer;
    border-radius: 6px;
    transition: background 0.5s;
}

.popover_item:hover {
    background-color: #dfdfdf;
}

.popover_item:active {
    background-color: #cdcdcd;
}

.popover_item_text {
    font-size: 14px;
    color: #605D64;
}

:global(.custom_popover_message .ant-popover-inner) {
    padding: 0;
    border-radius: 6px;
}

.recommend_start,
.recommend_start_active {
    width: 11px;
    height: 10px;
    margin-top: 3px;
    margin-right: 5px;
}

.popover_item_img_box {
    display: flex;
}

.recommend_start {
    background: url('../../../../statics/recommend_star.png') no-repeat center / 100% 100%;
}

.recommend_start_active {
    background: url('../../../../statics/recommend_star_active.png') no-repeat center / 100% 100%;
}

:global(.msg_images_id_box) {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

:global(.msg_images_id_container) {
    width: 200px;
    height: 200px;
    margin-right: 28px;
    /* margin-bottom: 10px; */
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

:global(.msg_images_id_container:last-child) {
    margin-right: 0;
}

:global(.msg_images_id_container_num1),
:global(.msg_images_id_container_num2) {
    width: 342px;
    height: 256px;
}

:global(.msg_images_id_container_num3) {
    width: 220px;
    height: 166px;
}

:global(.msg_images_id) {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    background-color: #f9f9f9 !important;
}

.content_thinking :global(.msg_images_id_container:hover .msg_images_id) {
    transform: none;
}

:global(.msg_images_id_container:hover .msg_images_id) {
    transform: scale(1.1);
}

.content_thinking :global(.msg_images_id_overlay) {
    display: none;
}

:global(.msg_images_id_overlay) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.15); /* 灰色半透明 */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

:global(.msg_images_id_container:hover .msg_images_id_overlay) {
    opacity: 1;
}

:global(.msg_images_id_overlay .msg_images_id_like) {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    height: 32px;
    background: url('../../../../statics/msg_images_id_like.png') no-repeat center / 100% 100%;
    transition: background 0.5s;
}

/* 隐藏伪元素用来预加载悬停背景图 */
:global(.msg_images_id_overlay .msg_images_id_like::after) {
    content: "";
    background: url('../../../../statics/msg_images_id_like_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

:global(.msg_images_id_overlay .msg_images_id_like:hover) {
    background: url('../../../../statics/msg_images_id_like_hover.png') no-repeat center / 100% 100%;
}

:global(.msg_images_id_overlay .msg_images_id_like_active),
:global(.msg_images_id_overlay .msg_images_id_like_active:hover) {
    background: url('../../../../statics/msg_images_id_like_active.png') no-repeat center / 100% 100%;
}