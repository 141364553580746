.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: default;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 100%;
}

.container_header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 30px;
    color: #101417;
}

.container_action_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
}

.action_list_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.action_list_right {

}

.container_content,
.container_detail_list_box,
.container_component_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: calc(100% - 50px);
}

.container_detail_list_box {
    align-items: flex-start;
}

.container_detail_list_main {
    width: 100%;
    height: 100%;
}

.container_content_left,
.container_content_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 48%;
    height: 100%;
    border-radius: 12px;
}

.container_detail_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.container_detail_list::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.container_detail_list::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.container_detail_list::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.left_components_title {
    font-size: 30px;
    font-weight: bold;
}

.left_components {
    width: 100%;
    height: calc(100% - 50px);
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.left_components::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.left_components::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.left_components::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.more_img {
    width: 12px;
    height: 13px;
}

.shopping_model_item_box,
.components_add {
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 12px;
    border: 2px solid #a6a6a6;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
}

.shopping_model_item_box:hover {
    border: 2px solid #74BDC6;
}

.components_add {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border: 2px solid #fff;
}

.components_add:hover {
    background-color: #f2f8f9;
}

.shopping_model_item_box_select {
    border: 2px solid #74BDC6;
}

.shopping_model_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* height: 93px; */
    padding: 10px 7px;
    border-radius: 12px;
}

.shopping_model_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    min-width: 51px;
    height: 51px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background-color: #FFF;
}

.shopping_model_img {
    width: 38px;
    height: 38px;
}

.shopping_model_text_box {
    width: calc(100% - 51px - 115px);
    padding: 0 5px;
}

.shopping_model_text_box_unowned {
    padding: 0 10px 0 14px;
}

.shopping_model_text_title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
}

.shopping_model_text_info {
    display: -webkit-box;        /* 创建一个弹性盒子 */
    -webkit-box-orient: vertical; /* 设置盒子的子元素排列方式为垂直 */
    -webkit-line-clamp: 3;       /* 限制显示两行 */
    overflow: hidden;            /* 隐藏超出内容 */
    text-overflow: ellipsis;     /* 使用省略号表示超出的文本 */
    color: #414648;
    font-size: 12px;
    line-height: 20px;
}

.shopping_model_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    border-radius: 6px;
    font-size: 11px;
}

.shopping_model_owned {
    width: 83px;
    min-width: 83px;
    color: #74bdc6;
    background-color: #e4f0f2;
    border: 1px solid #74bdc6;
    border-bottom-width: 2px;
}

.shopping_model_purchase {
    width: 128px;
    min-width: 128px;
    color: #EABC4E;
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-bottom-width: 2px;
}

.model_status_img {
    margin-right: 5px;
}

.right_header_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 118px;
}

.right_header_img {
    width: 100px;
    height: 100px;
}

.right_header_text_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.right_header_text {
    margin-bottom: 10px;
}

.introduce_box,
.example_box {
    width: 100%;
    padding: 10px 0;
}

.introduce_title,
.example_title {
    padding: 0 0 5px 10px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
}

.introduce_text,
.example_text {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9D9D9;
    background-color: #f9f9f9;
    border-radius: 12px;
    font-size: 12px;
    color: #414648;
}

.action_list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 115px;
}

.left_components_add {
    width: 100%;
    height: 50px;
    font-size: 30px;
}

.card_box_add,
.card_box {
    position: relative;
    width: 265px;
    margin: 0 15px 15px 0;
    min-height: 250px;
    max-height: 300px;
}

.card_box_add {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 6px;
    cursor: pointer;
}

.card_box_select .card_box_cover {
    display: block;
}

.card_box_cover {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 265px;
    height: 300px;
    z-index: 11;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.select_img_box {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 32px;
}

.card_box_cover_select,
.card_box_cover_unselect {
    user-select: none;
}

.detail_card :global(.ant-card-meta-detail .ant-card-meta-title) {
    font-size: 12px;
    white-space: wrap;
}

.detail_card :global(.ant-card-body) {
    padding: 5px !important;
}

.card_info_box {

}

.card_img {
    width: 265px;
    height: 200px;
}

.card_action_box {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.card_action_img_box {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.card_action_img_box:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.card_action_img {
    width: 25px;
    height: 25px;
}

.card_label_box {
    width: 100px;
    height: 25px;
}

.card_tag,
.card_untag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #6fda72;
    border-radius: 6px;
}

.card_untag {
    background-color: #66b6f1;
}
