html.dark pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}
html.dark code.hljs {
  padding: 3px 5px;
}
html.dark .hljs {
  color: #abb2bf;
  background: #282c34;
}
html.dark .hljs-keyword,
html.dark .hljs-operator,
html.dark .hljs-pattern-match {
  color: #f92672;
}
html.dark .hljs-function,
html.dark .hljs-pattern-match .hljs-constructor {
  color: #61aeee;
}
html.dark .hljs-function .hljs-params {
  color: #a6e22e;
}
html.dark .hljs-function .hljs-params .hljs-typing {
  color: #fd971f;
}
html.dark .hljs-module-access .hljs-module {
  color: #7e57c2;
}
html.dark .hljs-constructor {
  color: #e2b93d;
}
html.dark .hljs-constructor .hljs-string {
  color: #9ccc65;
}
html.dark .hljs-comment,
html.dark .hljs-quote {
  color: #b18eb1;
  font-style: italic;
}
html.dark .hljs-doctag,
html.dark .hljs-formula {
  color: #c678dd;
}
html.dark .hljs-deletion,
html.dark .hljs-name,
html.dark .hljs-section,
html.dark .hljs-selector-tag,
html.dark .hljs-subst {
  color: #e06c75;
}
html.dark .hljs-literal {
  color: #56b6c2;
}
html.dark .hljs-addition,
html.dark .hljs-attribute,
html.dark .hljs-meta .hljs-string,
html.dark .hljs-regexp,
html.dark .hljs-string {
  color: #98c379;
}
html.dark .hljs-built_in,
html.dark .hljs-class .hljs-title,
html.dark .hljs-title.class_ {
  color: #e6c07b;
}
html.dark .hljs-attr,
html.dark .hljs-number,
html.dark .hljs-selector-attr,
html.dark .hljs-selector-class,
html.dark .hljs-selector-pseudo,
html.dark .hljs-template-variable,
html.dark .hljs-type,
html.dark .hljs-variable {
  color: #d19a66;
}
html.dark .hljs-bullet,
html.dark .hljs-link,
html.dark .hljs-meta,
html.dark .hljs-selector-id,
html.dark .hljs-symbol,
html.dark .hljs-title {
  color: #61aeee;
}
html.dark .hljs-emphasis {
  font-style: italic;
}
html.dark .hljs-strong {
  font-weight: 700;
}
html.dark .hljs-link {
  text-decoration: underline;
}
html pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}
html code.hljs {
  padding: 3px 5px;
}
html code.hljs::-webkit-scrollbar {
  height: 4px;
}
html .hljs {
  color: #383a42;
  background: #ffffff;
}
html .hljs-comment,
html .hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}
html .hljs-doctag,
html .hljs-formula,
html .hljs-keyword {
  color: #a626a4;
}
html .hljs-deletion,
html .hljs-name,
html .hljs-section,
html .hljs-selector-tag,
html .hljs-subst {
  color: #e45649;
}
html .hljs-literal {
  color: #0184bb;
}
html .hljs-addition,
html .hljs-attribute,
html .hljs-meta .hljs-string,
html .hljs-regexp,
html .hljs-string {
  color: #50a14f;
}
html .hljs-attr,
html .hljs-number,
html .hljs-selector-attr,
html .hljs-selector-class,
html .hljs-selector-pseudo,
html .hljs-template-variable,
html .hljs-type,
html .hljs-variable {
  color: #986801;
}
html .hljs-bullet,
html .hljs-link,
html .hljs-meta,
html .hljs-selector-id,
html .hljs-symbol,
html .hljs-title {
  color: #4078f2;
}
html .hljs-built_in,
html .hljs-class .hljs-title,
html .hljs-title.class_ {
  color: #c18401;
}
html .hljs-emphasis {
  font-style: italic;
}
html .hljs-strong {
  font-weight: 700;
}
html .hljs-link {
  text-decoration: underline;
}
