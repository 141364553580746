.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 100%; */
    width: 280px;
    height: 100%;
    background-color: #000;
    /* padding: 13px 17px; */
    padding: 13px 0px;
}

.userinfo_box {
    width: 100%;
    height: 40px;
    padding: 0 17px;
}

.divider_box {
    width: 100%;
    padding: 0 17px;
}

.newchat_box {
    width: 100%;
    /* height: calc(100% - 142px); */
    height: calc(100% - 40px - 23px - 79px);
}

.upgrade_butn_container {
    width: 100%;
    padding: 0 17px;
}

.upgrade_butn_box {
    width: 100%;
    height: 61px;
    margin-top: 18px;
    background: url('../../../../statics/upgrade_butn_bg.png') no-repeat center / 100% 100%;
    transition: background-image 0.5s;
}

/* 隐藏伪元素用来预加载悬停背景图 */
.upgrade_butn_box::after {
    content: "";
    background: url('../../../../statics/upgrade_butn_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.upgrade_butn_box:hover {
    background: url('../../../../statics/upgrade_butn_hover.png') no-repeat center / 100% 100%;
}

.upgrade_butn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 61px;
    padding-top: 5px;
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    /* background-image: linear-gradient(to top right, #004e58 7%, #0a9ba8 54%, #2ec0ff 100%); */
}

/* .upgrade_butn_box:hover .upgrade_butn {
    background-image: linear-gradient(to top right, #138292 7%, #09bbcb 54%, #05b2fc 100%);
} */

.upgrade_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -18px;
    left: calc(50% - 20px);
    width: 37px;
    height: 37px;
    background-color: #000;
    border-radius: 50%;
}

.upgrade_img {
    width: 37px;
    height: 37px;
}
