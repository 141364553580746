.main {
    position: relative;
}

.butn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 121px;
    height: 56px;
    background: url('../../../../statics/ai_assistant_butn.png') no-repeat center / 100% 100%;
    cursor: pointer;
}

.chat_box {
    position: absolute;
    top: -420px;
    left: -120px;
    width: 240px;
    height: 400px;
}

.ai_main {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: #f6fafb;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.ai_header_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    padding: 0 8px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #fff;
}

.ai_content_box {
    width: 100%;
    height: calc(100% - 55px - 58px);
}

.ai_input_box {
    width: 100%;
    height: 58px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #fff;
}

.ai_header_online {
    width: 40px;
    height: 40px;
}

.ai_header_text_box {
    width: 130px;
    height: 40px;
}

.ai_header_title {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
    line-height: 20px;
}

.ai_header_status {
    font-family: "PingFangSCRegular";
    color: #414648;
    font-size: 12px;
    line-height: 20px;
}

.ai_header_close_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    cursor: pointer;
}

.ai_header_close_box:hover {
    background-color: #f0f0f0;
}

.ai_header_close_box:active {
    background-color: #d9d9d9;
}

.ai_header_close {
    width: 15px;
    height: 15px;
    background: url('../../../../statics/modal_image_close.png') no-repeat center / 100% 100%;
}

.textarea_box {
    position: relative;
    width: 100%;
    height: 58px;
}

.custom_textarea {
    height: 58px;
    min-height: 58px !important;
    max-height: 58px !important;
    padding: 10px 35px 10px 10px;
    font-size: 14px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #fff;
}

.custom_textarea::placeholder {
    /* padding-top: 10px; */
}

.custom_textarea:hover,
.custom_textarea:focus-within,
.custom_textarea:focus {
    border-color: #17a8b5;
}

/* 自定义滚动条 */
.custom_textarea::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.custom_textarea::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.custom_textarea::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
    cursor: default;
}

.send_butn_img {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.thinking_loading_box {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 9px;
    top: 9px;
    cursor: pointer;
    border-radius: 100%;
    user-select: none;
}

.circle_img {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0px;
    top: 0px;
    animation: spin 2s linear infinite;
}

.square_img {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: url('../../../../statics/thinking_square.png') no-repeat center / 100% 100%;
    transition: background 0.5s, box-shadow 0.5s;
}


/* 隐藏伪元素用来预加载悬停背景图 */
.thinking_loading_box::before .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box::after .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box:hover .square_img {
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
}

.thinking_loading_box:active .square_img {
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
}

.message_none_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 15px;
}

.message_none_title {
    font-size: 10px;
    color: #414648;
    margin-bottom: 8px;
}

.message_none_list {
    width: 100%;
}

.message_none_butn {
    width: 100%;
    height: 28px;
    border: 1px solid #17A8B5;
    margin-bottom: 8px;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
    color: #17A8B5;
    background-color: #ffffff;
    border-radius: 28px;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s;
    user-select: none;
}

.message_none_butn:hover {
    background-color: #e4f0f2;
}

.message_none_butn:active {
    color: #fff;
    background-color: #74bdc6;
}

.ai_content_list_box {
    width: 100%;
    height: 100%;
}
