.main :global(.ProseMirror) {
    width: 100%;
    height: 200px;
    border: 1px solid #ccc;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.main :global(.ProseMirror::-webkit-scrollbar) {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.main :global(.ProseMirror::-webkit-scrollbar-thumb) {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.main :global(.ProseMirror::-webkit-scrollbar-thumb:hover) {/*滚动条里面小方块*/
    background: #e3e3e3;
}

.main :global(.ProseMirror > * + *) {
    margin-top: 0.75em;
}

.main :global(.ProseMirror ul),
.main :global(.ProseMirror ol) {
    padding: 0 1rem;
}

.main :global(.ProseMirror h1),
.main :global(.ProseMirror h2),
.main :global(.ProseMirror h3),
.main :global(.ProseMirror h4),
.main :global(.ProseMirror h5),
.main :global(.ProseMirror h6) {
    line-height: 1.1;
}

.main :global(.ProseMirror img) {
    max-width: 90%;
    height: auto;
}

.main :global(.ProseMirror blockquote) {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.main :global(.ProseMirror hr) {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
}

.main :global(.ProseMirror strong),
.main :global(.ProseMirror b) {
    font-weight: bold;
}

.main :global(.ProseMirror em),
.main :global(.ProseMirror i) {
    font-style: italic;
}

/* 确保编辑器中各层级标题样式正确 */
.main :global(.ProseMirror h1) {
    font-size: 2rem;
    font-weight: bold;
}

.main :global(.ProseMirror h2) {
    font-size: 1.75rem;
    font-weight: bold;
}

.main :global(.ProseMirror h3) {
    font-size: 1.5rem;
    font-weight: bold;
}

/* 无序列表 */
.main :global(.ProseMirror ul) {
    list-style-type: disc;  /* 圆点符号 */
    padding-left: 20px;     /* 左边距 */
}

.main :global(.ProseMirror li) {
    margin-bottom: 5px;     /* 列表项之间的间距 */
}

/* 有序列表 */
.main :global(.ProseMirror ol) {
    list-style-type: decimal; /* 数字样式 */
    padding-left: 20px;       /* 左边距 */
}

/* 列表项 */
.main :global(.ProseMirror li) {
    margin-bottom: 5px;
}

/* 列表项缩进 */
.main :global(.ProseMirror li > ul),
.main :global(.ProseMirror li > ol) {
    margin-left: 20px;  /* 增加缩进 */
}

/* custom */
.is_active {
    background-color: #000;
    color: #fff;
}

.menu_toolbar {
    padding: 5px;
    border: 1px solid #ccc;
    border-bottom: none;
}

.menu_toolbar button {
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
}

.editor {
    border: 1px solid #ccc;
    padding: 10px;
    min-height: 300px;
    background-color: #fff;
}

.custom_btn {
    display: inline-block;
    padding: 10px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 2px;
    transition: all 0.3s ease;
    cursor: pointer;
    user-select: none;
}

.custom_btn:hover,
.custom_btn_active {
    border: 1px solid #3f96ff;
    color: #3f96ff;
}

.menu_toolbar .menu_upload_status {
    margin-left: 5px;
}