.modal_container {
    /* width: 1139px !important; */
    /* height: 775px; */
}

.modal_container :global(.ant-modal-content) {
    width: 100%;
    height: 775px;
    padding: 0 !important;
    border-radius: 20px;
    background-color: transparent;
    /* background: url('../../../../statics/update_info_bg.png') no-repeat center / 100% 100%; */
}

.modal_container :global(.ant-modal-body) {
    width: 100%;
    height: 100%;
}

.main {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-color: #ccc; */
    /* background-color: #fff; */
    /* background: url('../../../../statics/update_info_bg.png') no-repeat center / 100% 100%; */
}


.carousel_item,
.bg_img {
    max-width: 1139px;
    max-height: 775px;
    border-radius: 20px;
}

.modal_container :global(.ant-modal-close) {
    color: #f9f9f9;
    transition: background-color 0.5s;
}

.modal_container :global(.ant-modal-close:hover) {
    color: #fff;
    background-color: #aaa;
}

.modal_container :global(.ant-modal-close:active) {
    color: #fff;
    background-color: #ddd;
}

.header {
    width: 100%;
    padding: 60px 0;
    cursor: default;
}

.header_title {
    width: 100%;
    height: 60px;
    text-align: center;
    font-size: 38px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    color: #fff;
}

.section {
    width: 100%;
    padding: 0 100px;
    cursor: default;
}

.section_new {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 26px;
    border: 1px solid #e6e6e6;
    border-radius: 100px;
    font-size: 14px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    color: #17A8B5;
    background-color: #ffffff;
}

.section_update {
    font-size: 46px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    color: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 15px;
}

.section_update_info {
    font-size: 18px;
    font-family: 'PingFangSCMedium';
    color: #FFFFFF;
    margin-bottom: 32px;
}

/* .content_box {
    width: 100%;
    height: 200px;
} */

.update_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 293px;
    height: 199px;
    border-radius: 12px;
    background-color: #f9f9f9;
    padding: 30px 40px;
}

.item_header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 36px;
}

.item_model_img {
    width: 36px;
    height: 36px;
    /* margin-right: 55px; */
}

.item_model_name {
    font-size: 14px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    color: #1C1F25;
}

.item_content {
    width: 100%;
    height: calc(100% - 36px);
    padding-top: 15px;
    font-size: 14px;
    font-family: 'PingFangSCMedium';
    color: #414648;
}

.footer {
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
}

.done_butn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
}

.done_butn_arrow_img {
    width: 20px;
    height: 20px;
}

.carousel_container {
    height: 100%;
}

.carousel_container :global(.slick-slide) {
    text-align: center;
}

/* 调整箭头位置 */
.carousel_container :global(.slick-prev),
.carousel_container :global(.slick-next) {
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
}

.carousel_container :global(.slick-prev) {
    left: -25px;
}

.carousel_container :global(.slick-next) {
    right: -25px;
}

.carousel_container :global(.slick-dots) {
    bottom: 130px;
}

.carousel_container :global(.slick-dots li button) {
    border-radius: 50%; /* 使按钮变成圆形 */
    width: 10px;        /* 设置新的宽度 */
    height: 10px;       /* 设置新的高度 */
    background-color: #dcdee6;
}

.carousel_container :global(.slick-dots .slick-active button) {
    background-color: #17a8b5 !important; /* 设置激活状态的颜色，您可以根据需要更改颜色 */
}

.carousel_container :global(.slick-dots li),
.carousel_container :global(.slick-dots .slick-active) {
    width: 10px !important;
}
