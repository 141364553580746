.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.content {
    width: 100%;
    overflow: hidden;
}

.content_suggestion {
    /* margin-bottom: 85px; */
    margin-bottom: 60px;
}

.item_box {
    width: 60%;
    margin: 0 auto;
}

::selection {
    background-color: #cce2ff;
    color: rgba(0,0,0,.87);
}

.content_none {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    flex-grow: 1; /* 自动填充剩余空间 */
}

/* 自定义滚动条 */
.content_none::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.content_none::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.content_none::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.input_container {
    width: 100%;
    padding: 0 10px;
    padding-right: 80px;
}
.input_box {
    width: 60%;
    padding-right: 50px;
    margin: 0 auto;
}
.stop_responding {
    width: 110px;
    height: 42px;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
}
.content_mobile .input_container {
    padding: 0 20px 20px!important;
}
.content_ios_mobile .input_container {
    padding: 0 20px 40px!important;
}
.content_mobile .input_box {
    width: 100%!important;
    padding-right: 0!important;
}
.content_mobile .item_box {
    width: 100%;
}
.content_mobile .content_suggestion {
    margin-bottom: 0;
}
