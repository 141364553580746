html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
html, body {
    overflow: hidden;
    height: 100%;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* customize */
*,
:after,
:before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* user-select: none; */
    outline: none !important;
    /* font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif !important; */
    font-family: 'PingFangSCMedium', sans-serif;
}

a {
    cursor: pointer;
    text-decoration: none;
}

body {
    /* width: 100% !important; */
    overflow: hidden;
    font-family: 'PingFangSCMedium', sans-serif;
}

/* 禁止拖动图片 */
img {
    /* pointer-events: none; */
    -webkit-user-drag: none;
}

body {
    margin: 0;
    overflow: hidden;
    transition: transform 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out;
    transform-origin: top left;
    width: 100vw;
    height: 100vh;
}

#root {
    width: 100%;
    height: 100%;
}

.cite_container {
    position: relative;
    display: inline-block;
}

.cite_icon_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 25px;
    padding: 0 5px;
    background-color: #fff;
    border: 1px solid #E7E7E7;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    box-sizing: content-box;
}

.cite_icon {
    width: 15px;
    height: 15px;
    font-size: 12px;
}

.cite_tooltip {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

.cite_container:hover .cite_tooltip {
    visibility: visible;
    opacity: 1;
}

.cite_tooltip_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 25px;
    padding: 0 20px 1px 5px;
    border: 1px solid #E7E7E7;
    border-radius: 25px;
    background-color: #fff;
    box-sizing: content-box;
    cursor: pointer;
}

.cite_text {
    font-size: 12px;
    padding: 0 5px 1px;
    white-space: nowrap;
    color: #323A45;
}

.material_mode_query_img {
    max-height: 216px;
    border-radius: 6px;
}

.material_mode_info_container {
    width: 100%;
    height: 100%;
    padding: 4px 0 4px 4px;
    /* width: 790px; */
}

.material_mode_item {
    display: flex;
    padding: 15px;
    border: 1px solid #f9f9f9;
    border-radius: 12px;
    margin-top: 16px;
}

.material_mode_item:hover {
    background-color: #eeeeee;
    cursor: pointer;
    border: 1px solid #E1E1E1;
}

.material_mode_item:active {
    background-color: #ffffff;
    transition: all 0.3s ease;
}

.material_mode_number_box {
    flex-shrink: 0; /* 禁止缩小 */
    width: 30px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;
}

.material_mode_item_no {
    font-size: 12px;
    line-height: 27px;
}

.material_mode_item_number {
    font-size: 24px;
    font-weight: bold;
    line-height: 27px;
    color: #17a8b5;
}

.material_mode_item_image_box {
    flex-shrink: 0; /* 禁止缩小 */
    width: 125px;
    height: 60px;
    margin-right: 20px;
    /* overflow: hidden; 隐藏超出容器的部分 */
}

.material_mode_item_image {
    max-width: 100%;
    height: 60px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* 添加过渡效果 */
    cursor: pointer;
    border-radius: 6px;
}

.material_mode_item_image:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    transform: scale(1.1); /* 悬浮时放大 1.1 倍 */
}

.material_mode_item_text_box {
    flex-grow: 1;    /* 占据剩余空间 */
    flex-shrink: 1;  /* 允许缩小 */
    flex-basis: 0%;  /* 初始基准尺寸设为0 */
    margin-right: 6px;
    /* 或者简写为 */
    /* flex: 1; */
}

.material_mode_item_info {
    width: auto;
}

.material_mode_item_title,
.material_mode_item_text {
    font-size: 16px;
    line-height: 27px;
    color: #323a45;
}

.material_mode_item_title {
    font-weight: bold;
}

.material_mode_info_tabs_container {
    position: relative;
}

/* 隐藏单选按钮 */
[id^="material_mode_info_tab_id"]:nth-child(odd),
[id^="material_mode_info_tab_id"]:nth-child(even) {
    display: none;
}

/* Tab标签容器 */
.material_mode_info_tab {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 5px);
    padding-bottom: 16px;
    border-bottom: 1px solid #D9D9D9;
}

/* Tab标签样式 */
.material_mode_info_tab_label1,
.material_mode_info_tab_label1:hover,
.material_mode_info_tab_label2,
.material_mode_info_tab_label2:hover {
    padding: 4px 16px;
    font-weight: bold;
    line-height: 27px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
}

.material_mode_info_tab_label1 {
    margin: 0 6px;
}

.material_mode_info_tab_label2 {
    margin-left: 6px;
}

.material_mode_info_tab_label1:hover,
.material_mode_info_tab_label2:hover {
    background-color: #f0f0f0;
}

/* 选中状态样式 */
[id^="material_mode_info_tab_id"]:nth-child(odd):checked ~ .material_mode_info_tab .material_mode_info_tab_label1,
[id^="material_mode_info_tab_id"]:nth-child(even):checked ~ .material_mode_info_tab .material_mode_info_tab_label2 {
    background: #17a8b5;
    color: #fff;
}

.material_mode_info_tab_div_line {
    width: 2px;
    height: 35px;
    background-color: #d9d9d9;
}


/* 内容项通用样式 */
.material_mode_info_tab_content {
    display: none;
    width: 100%;
    max-height: 810px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.material_mode_info_tab_content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

/*滚动条里面小方块*/
.material_mode_info_tab_content::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.material_mode_info_tab_content::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

/* 显示选中的内容 */
[id^="material_mode_info_tab_id"]:nth-child(odd):checked ~ .material_mode_info_tab_contents .material_mode_info_tab_content1,
[id^="material_mode_info_tab_id"]:nth-child(even):checked ~ .material_mode_info_tab_contents .material_mode_info_tab_content2 {
    display: block;
}
