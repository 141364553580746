.container {
    width: 100%;
    height: 100%;
}

.header {
    width: 100%;
    height: 60px;
}

.header__crumbs__textDisabled,
.header__crumbs__text {
    font-size: 16px;
    line-height: 27px;
    color: #abafb7;
}

.header__crumbs__text {
    color: #323a45;
}

.header__crumbs__textDisabled,
.header__crumbs__text,
.header__crumbs__icon {
    margin-right: 11px;
    user-select: none;
}

.section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 340px;
}

.section__left {
    display: flex;
    justify-content: space-between;
    width: 40%;
    height: 100%;
}

.section__left__minpic {
    width: 73px;
    height: 100%;
}

.left__img__box {
    width: 73px;
    height: 73px;
    border: 2px solid #17a8b5;
    border-radius: 6px;
    cursor: pointer;
}

.left__img__box .left__img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 6px;
}

.section__left__maxpic {
    width: calc(100% - 80px);
    max-height: 340px;
}

.left__img {
    max-width: 100%;
    max-height: 100%;
}

.section__left__maxpic .left__img {
    cursor: pointer;
}

.section__right {
    width: 55%;
    height: 100%;
}

.right__header {
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
}

.right__header,
.right__section {
    margin-bottom: 10px;
}

.right__section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.right__section__title {
    width: 20%;
    font-size: 16px;
    line-height: 24px;
    color: #323a45;
    margin-right: 16px;
}

.right__section__text {
    width: 80%;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    color: #101417;
}

.right__section__info {
    width: 80%;
    font-size: 14px;
    line-height: 21px;
    color: #323a45;
}