.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    height: 100%;
    padding: 30px 50px;
}

.left_header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.logo {
    width: 34px;
    height: 41px;
}

.logo_text {
    margin-left: 10px;
    font-size: 24px;
    color: #fff;
}

.left_middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 170px;
    margin-top: 200px;
}

.welcome {
    font-size: 60px;
    font-weight: bold;
    color: #fff;
}

.explain {
    width: 438px;
    font-size: 24px;
    color: #fff;
    letter-spacing: 2px;
    line-height: 1.3;
}

.right {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    padding: 30px 100px;
    background-color: #fff;
}

.right_header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80px;
    margin-top: 50px;
}

.login_text {
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 15px;
}

.login_explain {
    font-size: 16px;
    color: #7e8b9e;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 24px;
}

.register_form {
    padding-top: 10px;
}

.error_info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 43px;
    border-radius: 6px;
    margin-bottom: 24px;
    background-color: #fde0d0;
    color: #E31A1A;
}

.register_error_info {
    height: 30px;
    margin-bottom: 10px;
}

.no_error_info {
    background-color: #fff;
}

.form_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    margin-bottom: 24px;
}

.item_title {
    font-size: 14px;
    color: #323A46;
    font-weight: bold;
}

.item_input {
    width: 100%;
    height: 43px;
    color: #7E8B9E;
    border-radius: 6px;
    background-color: #FAFAFC;
}

.item_input:focus-within,
.item_input:hover,
.item_input:focus {
    border: 1px solid #17a8b5 !important;
}

.item_input::placeholder {
    color: #FAFAFC;
}

.item_input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
    box-shadow: 0 0 0px 1000px #FFF inset;
}

.remember_forget_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    margin-bottom: 24px;
}

.protocol_box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    margin-bottom: 24px;
}

.item_remember,
.item_forget {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 133px;
    height: 25px;
    font-size: 14px;
    color: #7E8B9E;
}

.item_forget {
    justify-content: flex-end;
    color: #17a8b5;
    cursor: pointer;
}

.submit_box {
    width: 100%;
    height: 43px;
}

.submit_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    cursor: pointer;
    background-color: #abafb7;
    color: #fff;
    background: url('../../../../statics/login_butn.png') no-repeat center / 100% 100%;
    transition: background-image 0.5s;
}

/* 隐藏伪元素用来预加载悬停背景图 */
.submit_butn::after {
    content: "";
    background: url('../../../../statics/login_butn_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.submit_butn_active {
    background: url('../../../../statics/login_butn_active.png') no-repeat center / 100% 100%;
    transition: background 0.5s, box-shadow 0.5s;
}

/* 隐藏伪元素用来预加载悬停背景图 */
.submit_butn_active::before {
    content: "";
    background: url('../../../../statics/login_butn_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.submit_butn_active::after {
    content: "";
    background: url('../../../../statics/login_butn_active_click.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.submit_butn_active:hover {
    background: url('../../../../statics/login_butn_hover.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.submit_butn_active:active {
    background: url('../../../../statics/login_butn_active_click.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.third_login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
}

.register_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20px;
    margin: 15px 0;
}

.register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 13px;
    color: #7e8b9e;
}

.register_link {
    color: #3568ff;
}

.register_link:hover {
    text-decoration: underline;
}

.right_footer {
    position: absolute;
    left: calc(50% - 56px);
    bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 75px;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 112px;
    height: 100%;
}

.footer_img {
    width: 48px;
    height: 54px;
}

.footer_text {
    font-size: 13px;
    color: #7E8B9E;
}

.item_agree {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 25px;
    font-size: 14px;
    color: #7E8B9E;
}

.item_protocol {
    color: #FF8682;
}

.code_card_main {
    width: 100%;
    padding-top: 200px;
}

.code_card_header {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 42px;
    height: 42px;
    background: url('../../../../statics/code_card_go_back.png') no-repeat center / 100% 100%;
    cursor: pointer;
}

.code_card_error_info_box {
    padding-top: 24px;
}

.code_card_code_box {
    margin-bottom: 30px;
}