.register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 13px;
    color: #7e8b9e;
}

.register_link {
    color: #3568ff;
}

.register_link:hover {
    text-decoration: underline;
}