.custom_collapse:global(.ant-collapse) {
    /* border: none !important; */
    user-select: none;
}

.custom_collapse :global(.ant-collapse-header) {
    display: flex;
    justify-content: flex-start !important;
    align-items: center !important;
    width: 100%;
    height: 40px;
    padding: 0 !important;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 6px !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0px;
    z-index: 1000;
    background-color: #000;
}

.custom_collapse :global(.ant-collapse-header:hover) {
    background-color: #262626;
}

.custom_collapse :global(.ant-collapse-header:active) {
    background-color: #404040;
}

.custom_collapse :global(.ant-collapse-item) {
    /* border-bottom: none !important; */
}

.custom_collapse :global(.ant-collapse-content) {
    /* border: none !important; */
    background-color: transparent;
}

.custom_collapse :global(.ant-collapse-content-box) {
    padding: 0 !important;
}

.custom_collapse :global(.ant-collapse-header .ant-collapse-expand-icon) {
    padding-inline-end: 10px !important;
}
