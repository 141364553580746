.main {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 6px;
    background-color: #ffffff;
}

.item_box {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 50px;
}

.item_title {
    width: 40%;
    text-align: right;
    padding-right: 15px;
}

.item_count_message {
    width: 300px;
    height: 32px;
    padding-left: 20px;
    line-height: 32px;
    color: #f00;
}