.main {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 0 10px;
    padding-bottom: 5px;
}

.main_evenly {
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 0px;
}

.lv_n_icon {
    width: 23px;
    height: 23px;
}

.grade,
.experience {
    font-size: 12px;
    color: #c1c1c1;
}

.experience {
    width: auto;
}

.progress_box {
    min-width: 35%;
    max-width: 45%;
}

.progress_trail {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #e5e5e5;
    border-radius: 4px;
}

.progress_stroke {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 2px 5px 0px 5px;
    background-color: #3caae6;
    border-radius: 4px;
}

.progress_stroke_white {
    width: 100%;
    height: 50%;
    background-color: #63bbeb;
    border-radius: 4px;
}
