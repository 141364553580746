.main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.input {
    width: 56px;
    height: 56px;
    border: 2px solid #CBD1D8;
    text-align: center;
    border-radius: 12px;
    font-size: 24px;
}

.input_active {
    border: 2px solid #17a8b5;
}