.header {
    margin-bottom: 25px;
}

.title {
    font-size: 30px;
}

.user {
    font-size: 20px;
    margin-top: 25px;
}