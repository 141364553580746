.main {
    position: relative;
    width: 100%;
    height: 100%;
}

.header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    margin-bottom: 25px;
}

.back_butn {
    position: absolute;
    left: 0;
    top: 0;
}

.title {
    font-size: 30px;
}

/* .container {
    width: 100%;
    min-height: calc(100% - 60px);
    padding: 20px;
    border-radius: 6px;
    background-color: #ffffff;
} */

.form_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100% - 60px);
    padding: 20px;
    border-radius: 6px;
    background-color: #ffffff;
}