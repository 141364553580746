.floating_window {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    padding: 50px 20px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.custom_close_button {
    position: absolute;
    top: 5px;
    left: 5px;
}

.custom_export_button {
    position: absolute;
    top: 5px;
    left: 65px;
}

.custom_update_button {
    position: absolute;
    top: 5px;
    left: 205px;
}

.custom_params_name {
    position: absolute;
    right: 20px;
    top: 6px;
    padding: 10px;
    font-size: 24px;
    background-color: #e6e6e6;
}

.floating_window_content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #e6e6e6;
}
