.main {
    width: 100%;
}

.header {
    height: 35px;
    margin-bottom: 25px;
}

.title {
    font-size: 30px;
}

.container {
    width: 100%;
    min-height: calc(100% - 60px);
    padding: 10px;
    border-radius: 6px;
    background-color: #ffffff;
}

.add_box {
    width: 100%;
    height: 50px;
}

.table_box {
    width: 100%;
}

.preview_box {
    width: 100%;
    height: 500px;
    padding-top: 30px;
    border-top: 1px solid #ccc;
    margin-top: 30px;
}

.preview_title {
    font-size: 20px;
    padding: 5px 0;
}

.preview_box_banner {
    width: 70%;
    height: 133px;
    border: 1px solid #ccc;
    border-radius: 12px;
    margin: 0 auto;
}

.publish_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}
