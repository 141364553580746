.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 63px;
}
.textarea_box {
    position: relative;
    width: 100%;
}

.custom_textarea {
    /* width: 100%;
    height: 100% !important;
    min-height: 48px !important;
    max-height: 100% !important;
    padding-right: 40px;
    overflow-y: auto */
    min-height: 48px !important;
    padding: 10px 35px 10px 10px;
    font-size: 16px;
}

.custom_textarea:hover,
.custom_textarea:focus-within,
.custom_textarea:focus {
    border-color: #17a8b5;
}

/* 自定义滚动条 */
.custom_textarea::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.custom_textarea::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.custom_textarea::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
    cursor: default;
}

.send_butn_img {
    position: absolute;
    right: 10px;
    bottom: 15px;
    cursor: pointer;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.thinking_loading_box {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 9px;
    top: 9px;
    cursor: pointer;
    border-radius: 100%;
    user-select: none;
}

.circle_img {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0px;
    top: 0px;
    animation: spin 2s linear infinite;
}

.square_img {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: url('../../../../statics/thinking_square.png') no-repeat center / 100% 100%;
    transition: background 0.5s, box-shadow 0.5s;
}


/* 隐藏伪元素用来预加载悬停背景图 */
.thinking_loading_box::before .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box::after .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box:hover .square_img {
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
}

.thinking_loading_box:active .square_img {
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
}


.textarea_explain {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 38px;
    padding: 13px 0;
    font-size: 12px;
    color: #9A9B9F;
}

.error_box,
.suggestion_box {
    position: absolute;
    left: 0;
    /* top: -75px; */
    top: -50px;
    width: 100%;
    /* height: 75px; */
    height: 50px;
    background-color: #fff;
    padding-left: 63px;
}

.suggestion_title {
    display: flex;
    width: 100%;
    height: 25px;
    font-size: 13px;
    color: #9A9B9F;
}

.suggestion_item_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 35px;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 20px;
    padding: 5px 20px;
    border: 1px solid #C1C1C1;
    cursor: pointer;
    text-align: center;
}

.item:hover {
    background-color: #e4f0f2;
}

.item1 {
    width: 100%;
}

.item2 {
    width: 49%;
}

.item3 {
    width: 32%;
}

.item_text {
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #4f4f4f;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.error_message_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    height: 42px;
    margin-top: 4px;
}

.error_message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    border: 1px solid #E31A1A;
    border-radius: 6px;
    font-size: 16px;
    color: #E31A1A;
    background-color: #fde0d0;
}

.error_icon {
    margin-right: 12px;
}
.content_mobile {
    padding-left: 0!important;
}
.content_mobile .suggestion_box {
    padding-left: 0;
}
.content_mobile .textarea_explain {
    display: none;
}
.content_mobile .suggestion_box {
    display: none;
}
.content_mobile .item_text {
    font-size: 14px;
}
