
.content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 10px 15px 10px;
}

/* 自定义滚动条 */
.content::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.content::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.content::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.item_box {
    width: 100%;
    margin: 0 auto;
}

::selection {
    background-color: #cce2ff;
    color: rgba(0,0,0,.87);
}
