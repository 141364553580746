.butn_box {
    width: 160px;
    height: 50px;
}

.butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 50px;
    border-radius: 6px;
    letter-spacing: 2px;
    cursor: pointer;
    color: #fff;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    user-select: none;
}

.butn:hover {
}

.butn:active {
    top: 3px;
    height: calc(100% - 3px) !important;
}

.primary {
    color: #fff;
    background-color: #17a8b5;
    border-bottom: 3px solid #12828c;
}

.primary:hover {
    background-color: #00bbcc;
}

.primary:active {
    background-color: #17a8b5;
    border-bottom-width: 0px;
}

.light {
    color: #17A8B5;
    background-color: #ffffff;
    border: 1px solid #12828c;
    border-bottom-width: 4px;
}

.light:hover {
    background-color: #e4f0f2;
}

.light:active {
    background-color: #ffffff;
    border-bottom-width: 1px;
}

.default {
    color: #A6A6A6;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-bottom-width: 4px;
}

.default:hover {
    background-color: #eeeeee;
}

.default:active {
    background-color: #ffffff;
    border-bottom-width: 1px;
}