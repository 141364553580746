.main {
    width: 100%;
    height: 100%;
}

.container_component_header {
    width: 100%;
    height: 50px;
}

.container_component_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: calc(100% - 50px);
}

.container_detail_list_box {
    align-items: flex-start;
}

.container_detail_list_main {
    width: 100%;
    height: 100%;
}

.container_content_left,
.container_content_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    height: 100%;
    border-radius: 12px;
}

.container_content_side {
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.container_detail_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.container_detail_list::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.container_detail_list::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.container_detail_list::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.left_components_title {
    font-size: 30px;
    font-weight: bold;
}

.left_components {
    width: 100%;
    height: calc(100% - 50px);
    padding: 0 20px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.left_components::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.left_components::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.left_components::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.more_img {
    width: 12px;
    height: 13px;
}

.shopping_model_item_box,
.components_add {
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 12px;
    border: 2px solid #a6a6a6;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
}

.shopping_model_item_box:hover {
    border: 2px solid #74BDC6;
}

.components_add {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border: 2px solid #fff;
}

.components_add:hover {
    background-color: #f2f8f9;
}

.shopping_model_item_box_select {
    border: 2px solid #74BDC6;
}

.shopping_model_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* height: 93px; */
    padding: 10px 7px;
    border-radius: 12px;
}

.shopping_model_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    min-width: 51px;
    height: 51px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background-color: #FFF;
}

.shopping_model_img {
    width: 38px;
    height: 38px;
}

.shopping_model_text_box {
    width: calc(100% - 51px);
    padding: 0 5px;
}

.shopping_model_text_box_unowned {
    padding: 0 10px 0 14px;
}

.shopping_model_text_title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
}

.shopping_model_text_info {
    display: -webkit-box;        /* 创建一个弹性盒子 */
    -webkit-box-orient: vertical; /* 设置盒子的子元素排列方式为垂直 */
    -webkit-line-clamp: 3;       /* 限制显示两行 */
    overflow: hidden;            /* 隐藏超出内容 */
    text-overflow: ellipsis;     /* 使用省略号表示超出的文本 */
    color: #414648;
    font-size: 12px;
    line-height: 20px;
}

.container_content_right {
    padding: 20px;
    border: 1px solid #a6a6a6;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
}

.right_info {
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.right_info::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.right_info::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.right_info::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.right_header_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 118px;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    margin: 0 auto;
}

.right_header_img {
    width: 100px;
    height: 100px;
}

.right_header_text_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.right_header_text {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 18px;
    color: #101417;
    margin: 10px 0 20px 0;
}

.introduce_box,
.example_box {
    width: 100%;
    padding: 10px 0;
}

.introduce_title,
.example_title {
    padding: 0 0 5px 10px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
}

.introduce_text,
.example_text {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9D9D9;
    background-color: #f9f9f9;
    border-radius: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #414648;
}

.example_text img {
    max-width: 100%;
}
