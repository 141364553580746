.textarea_box {
    position: relative;
    width: 100%;
    padding: 10px 40px 50px 40px;
    font-size: 16px;
    border: 1px solid #c3c7cf;
    border-radius: 6px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.textarea_box:hover,
.textarea_box:focus-within,
.textarea_box:focus {
    border-color: #17a8b5;
}

.textarea_image {
    padding: 80px 40px 50px 40px;
}

.custom_textarea {
    /* width: 100%;
    height: 100% !important;
    min-height: 48px !important;
    max-height: 100% !important;
    padding-right: 40px;
    overflow-y: auto */
    /* min-height: 48px !important; */
    /* padding: 10px 35px 46px 10px; */
    /* background-color: #f9f9f9; */
    min-height: 50px !important;
    /* padding: 10px 35px 46px 46px;
    font-size: 16px;
    border: 1px solid #c3c7cf;
    border-radius: 6px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
    padding: 0 5px 0 0 !important;
    border: none;
}

.custom_textarea:hover,
.custom_textarea:focus-within,
.custom_textarea:focus {
    border: none !important;
    box-shadow: none !important;
    /* border-color: #17a8b5; */
}

/* 自定义滚动条 */
.custom_textarea::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.custom_textarea::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.custom_textarea::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
    cursor: default;
}

.send_butn_img {
    position: absolute;
    right: 10px;
    /* top: 15px; */
    bottom: 75px;
    cursor: pointer;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.thinking_loading_box {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    border-radius: 100%;
    user-select: none;
}

.circle_img {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0px;
    top: 0px;
    animation: spin 2s linear infinite;
}

.square_img {
    width: 12px;
    height: 12px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: url('../../../../statics/thinking_square.png') no-repeat center / 100% 100%;
    transition: background 0.5s, box-shadow 0.5s;
}


/* 隐藏伪元素用来预加载悬停背景图 */
.thinking_loading_box::before .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box::after .square_img {
    content: "";
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.thinking_loading_box:hover .square_img {
    background: url('../../../../statics/thinking_square_hover.png') no-repeat center / 100% 100%;
}

.thinking_loading_box:active .square_img {
    background: url('../../../../statics/thinking_square_active.png') no-repeat center / 100% 100%;
}
.mobile_textarea_box .custom_textarea {
    height: 42px!important;
    min-height: 42px!important;
    line-height: 30px;
    padding: 6px 46px 6px 20px;
    font-size: 14px;
    border-radius: 20px;
}
.mobile_textarea_box .send_butn_img {
    right: 20px;
    bottom: 12px;
}
.mobile_textarea_box .circle_img {
    width: 24px;
    height: 24px;
}
.mobile_textarea_box .thinking_loading_box, .mobile_textarea_box .square_img {
    right: 6px;
    top: 6px;
}

.reasoning_box {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
}

.reasoning {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 36px;
    border: 1px solid #c1c1c1;
    margin-right: 16px;
    border-radius: 36px;
    background-color: #f9f9f9;
    user-select: none;
    cursor: pointer;
}

.reasoning:hover {
    background-color: #e4f0f2;
}

.reasoning_active {
    border: 1px solid #17a8b5;
    color: #17a8b5;
    background-color: #e4f0f2;
}

.reasoning_img {
    margin-right: 2px;
}

.reasoning_text {
    font-size: 16px;
    line-height: 24px;
}

.material_upload_active_box,
.material_upload_box {
    position: absolute;
    left: 10px;
    /* top: 10px; */
    bottom: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    cursor: default;
}

.material_upload_active_box {
    cursor: pointer;
}

.preview_image_container {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 56px;
    height: 56px;
}

.preview_image_box {
    position: absolute;
}

.preview_close {
    position: absolute;
    right: -10px;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: #fff;
    cursor: pointer;
}

.preview_close_img {

}

.material_mode_uploading_box {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #C3C7CF;
    border-radius: 6px;
}

.material_mode_uploading_dashed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px dashed #C3C7CF;
    border-radius: 6px;
    background-color: #f2f8f9;
}

.uploading_preview_image_box {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
}

.uploading_preview_image {
    width: auto;
    max-height: 64px;
    display: block;
}

.material_mode_uploading_progress {
    width: 40%;
    height: 20px;
}