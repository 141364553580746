.container {
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    overflow: hidden;
}

.container :global(.even_row) {
    background-color: #ffffff;
}

.container :global(.odd_row) {
    background-color: #f5f5f5;
}
