.main {
    position: relative;
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 25px;
}

.title {
    font-size: 30px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 60px);
    min-height: calc(100% - 60px);
    padding: 20px;
    border-radius: 6px;
    background-color: #ffffff;
}

.container_null {
    padding: 20px;
    font-size: 20px;
    text-align: center;
}

.container_left {
    width: 65%;
}

.container_right {
    width: 35%;
}

.pie_left_item {
    width: 100%;
    height: 100%;
}

.pie_right_item {
    width: 100%;
    height: 33%;
}

.floating_window {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    padding: 50px 20px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.custom_close_button {
    position: absolute;
    top: 5px;
    left: 5px;
}

.floating_window_content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #e6e6e6;
}

.item_box {
    display: flex;
    align-items: center;
}
