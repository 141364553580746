.container {
    width: 100%;
    height: 100%;
    padding: 40px;
}

.header {
    width: 100%;
    height: 11px;
}

.step_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.step {
    width: 150px;
    height: 11px;
    border-radius: 99px;
    background-color: #dadada;
    cursor: pointer;
}

.step:not(:last-child) {
    margin-right: 25px;
}

.step_active {
    background-color: #17a8b5;
}

.main {
    width: 100%;
    height: calc(100% - 11px - 43px);
    padding-top: 80px;
}

.gender_info_box {
    width: 100%;
    height: 100%;
}

.gender_topic {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #323A45;
    margin-bottom: 50px;
}

.gender_bg_img_box,
.common_bg_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90px;
    margin-bottom: 50px;
}

.gender_bg_img {
    width: 90px;
    height: 90px;
}

.gender_ask,
.common_ask {
    margin-bottom: 50px;
    font-size: 20px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    text-align: center;
}

.gender_butn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
}

.gender_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 121px;
    height: 100%;
    user-select: none;
}

.gender_item:not(:last-child) {
    margin-right: 60px;
}

.gender_item_img {
    width: 80px;
    height: 82px;
    cursor: pointer;
}

.gender_man_img {
    height: 86px;
}

.item_butn_box {
    width: 121px;
    height: 44px;
}

.item_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 121px;
    height: 44px;
    border: 1px solid #e0e3e8;
    border-bottom-width: 4px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.5s, border-color 0.5s, color 0.5s;
    position: relative;
}

.item_butn:hover {
    color: #17a8b5;
    border-color: #17a8b5;
    background-color: #e4f0f2;
}

.item_butn:active {
    background-color: #a2cfd6;
    top: 3px;
    height: calc(100% - 3px) !important;
    border-bottom-width: 1px;
}

.item_butn_active {
    color: #17a8b5;
    background-color: #fff;
    border: 1px solid #17a8b5;
    border-bottom-width: 4px;
}

.item_butn_active:hover {
    background-color: #c6e0e4;
    box-shadow: none;
}

.footer {
    width: 100%;
    height: 43px;
}

.footer_butn_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.my_button {
    width: 133px;
    height: 100%;
}

.previous {
    margin-right: 16px;
}

.common_info_box {
    padding-top: 86px;
}

.common_bg_img_box,
.common_ask {
    margin-bottom: 35px;
}

.common_butn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
}

.select {
    width: 560px;
    height: 40px;
    border-color: #cbd1d8;
    background-color: #fafafc;
}

.select_open {

}

.select :global(.ant-select-selector) {
    border-color: #cbd1d8 !important;
    box-shadow: none !important;
}

.select :global(.ant-select-selection-item) {
    color: #4F4F4F;
}

.select_open :global(.ant-select-selector) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

:global(.custom_select.ant-select-dropdown) {
    border: 1px solid #cbd1d8;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    margin-top: -4px !important;
    box-shadow: none !important;
    background-color: #fafafc;
}

:global(.custom_select .ant-select-item-option-active) {
    background-color: #e4f0f2 !important;
    color: #4F4F4F;
}

:global(.custom_select .ant-select-item-option-active:active) {
    background-color: #a2cfd6 !important;
    color: #4F4F4F;
}

:global(.custom_select .ant-select-item-option-selected:not(.ant-select-item-option-disabled)),
:global(.custom_select .ant-select-item-option-selected) {
    background-color: #c6e0e4 !important;
    color: #17a8b5;
}

:global(.custom_select .rc-virtual-list-scrollbar-thumb) {
    background-color: #cbd1d8 !important;
}