.carousel_container {
    width: 100%;
    height: 100%;
}

.carousel_container :global(.slick-list) {
    border-radius: 12px;
}

.carousel_container :global(.slick-slide),
.carousel_container :global(.slick-slide>div) {
    width: 100% !important;
    height: 133px !important;
}

.carousel_item {
    width: 100%;
    height: 100%;
}

.carousel_item_bg_img {
    width: 100%;
    height: 100%;
}