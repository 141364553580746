.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.main {
    width: 100%;
    height: 100%;
}

.item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 6px;
}

.item:hover {
    background-color: #262626;
}

.item:active {
    background-color: #404040;
}

.item_active_edit {
    background-color: #262626;
}

.item_active,
.item_active:hover {
    background-color: #404040;
}

.collapsibleContent {
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.item_hide {
    display: none;
}

.item_collapsed {
    max-height: 0;
}

.item_expanded {
    max-height: 3000px;
}

.expert_butn_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px);
    height: 100%;
    cursor: pointer;
}

.item_img {
    width: 14px;
    height: 14px;
}

.item_title,
.item_text {
    width: calc(100% - 30px - 40px);
    color: #e7e7e7;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.item_title {
    font-family: 'PingFangSCRegular', sans-serif;
}

.item_text_box {
    width: calc(100% - 14px - 35px);
    padding: 0 5px;
}

.item_text {
    width: 100%;
}

.shrink_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40px;
    height: 40px;
}

.shrink_img {
    width: 11px;
    height: 6px;
}

.none_history_img {
    width: 11px;
    height: 3px;
}

.butn_box {
    width: 35px;
    height: 100%;
}


.popover_box {

}

.popover_item {
    width: 150px;
    height: 30px;
}

.popover_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.5s;
}

.popover_item:hover {
    background-color: #dfdfdf;
}

.popover_item:active {
    background-color: #cdcdcd;
}

.popover_item_img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.popover_item_edit,
.popover_item_delete {
    font-size: 14px;
}


.popover_item_delete{
    color: #E31A1A;
}

:global(.custom_popover_setting .ant-popover-content .ant-popover-inner) {
    padding: 5px;
}

.butns_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.butns_more_img {
    width: 12px;
    height: 12px;
}
