.home {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, .2);
    border-radius: 6px;
}

.home_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

.home_butn:hover {
    background: rgba(255, 255, 255, .3);
}

.home_butn:active {
    background: rgba(255, 255, 255, .4);
}