.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px 0 0;
    font-size: 25px;
    color: #888;
}

.left {
    width: 300px;
    height: 100%;
    padding-left: 18px;
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search_box {
    position: relative;
    width: 200px;
    height: 40px;
    margin-right: 20px;
}

.search {
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px 30px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    font-size: 14px;
}

.search_icon {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 11px;
    height: 12px;
}

.search_box_mask {
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
}

.img_box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}

/* .img_box:hover {
    background-color: #ccc;
} */

.img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.feedback_img {
    background-image: url('../../../../statics/menu_feedback.png');
}

.feedback_img:hover {
    background-image: url('../../../../statics/menu_feedback_active.png');
}

.collect_img {
    background-image: url('../../../../statics/menu_collect.png');
}

.collect_img:hover {
    background-image: url('../../../../statics/menu_collect_active.png');
}

.setting_img {
    background-image: url('../../../../statics/menu_setting.png');
}

.setting_img:hover {
    background-image: url('../../../../statics/menu_setting_active.png');
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.fade_in {
    animation-name: fadeIn;
    /* 动画持续时间为0.5秒 */
    animation-duration: 0.5s;
    /* 动画完成后保持最终状态 */
    animation-fill-mode: forwards;
}

.back_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #f2f2f2;
    border: 1px solid #CBD1D8;
    border-radius: 6px;
    cursor: pointer;
}

.back_img {
    width: 16px;
    height: 16px;
}