.main {
    display: flex;
    flex-wrap: wrap;
}

.card {
    width: 200px;
    margin-right: 20px;
    margin-bottom: 30px;
}
