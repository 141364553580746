.container  {
    width: 100%;
    height: 100%;
}

.img {
    width: 20px;
    height: 20px;
    background: url('../../../../statics/add_new_topic.png') no-repeat center / 100% 100%;
    transition: background 0.5s;
}

.img::after {
    content: "";
    background: url('../../../../statics/add_new_topic_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.text {
    font-size: 14px;
    color: #17A8B5;
    margin-left: 10px;
    transition: color 0.5s;
}
