.modal_container :global(.ant-modal-content) {
    width: 100%;
    height: 820px;
    border-radius: 20px;
}

.modal_container :global(.ant-modal-body) {
    width: 100%;
    height: 93%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.main {
    display: flex;
    width: 100%;
    height: 90%;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7%;
    border-top: 1px solid #ccc;
}

.main_left,
.main_right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    height: 100%;
}

.preview_img_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 93%;
    padding-bottom: 15px;
}

.preview_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85%;
}

.preview_img_name_box {
    width: 100%;
    height: 15%;
    padding: 10px 50px 0 50px;
}

.preview_img {
    max-width: 100%;
    max-height: 100%;
}

.detail_card :global(.ant-card-meta-detail .ant-card-meta-title) {
    font-size: 12px;
    white-space: wrap;
}

.detail_card :global(.ant-card-cover) {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right_top {
    width: 100%;
    height: 40%;
}

.right_bottom {
    width: 100%;
    height: 60%;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.right_bottom::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.right_bottom::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.right_bottom::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.card_add_img_box {
    width: 100%;
    height: 100%;
}

.components_add_img {

}