.modal_container {
    /* width: 590px !important; */
    /* height: 606px; */
}

.modal_container :global(.ant-modal-content) {
    width: 100%;
    height: 606px;
    padding: 0 !important;
    background: url('../../../../statics/experience_update_info.png') no-repeat center / 100% 100%;
    border-radius: 15px;
}

.modal_container :global(.ant-modal-body) {
    width: 100%;
    height: 100%;
}

.modal_container :global(.ant-modal-close) {
    color: #f9f9f9;
    transition: background-color 0.5s;
}

.modal_container :global(.ant-modal-close:hover) {
    color: #fff;
    background-color: #aaa;
}

.modal_container :global(.ant-modal-close:active) {
    color: #fff;
    background-color: #ddd;
}

.main {
    width: 100%;
    height: 100%;
    padding: 64px 100px;
    /* background-color: #fff; */
    /* background: url('../../../../statics/update_info_bg.png') no-repeat center / 100% 100%; */
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 46px;
    line-height: 46px;
    margin-bottom: 50px;
}

.title {
    font-size: 38px;
    color: #fff;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.icon {
    width: 173px;
    height: 135px;
    margin-bottom: 10px;
}

.progress {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
}

.info_box {
    width: 100%;
    margin-bottom: 10px;
}

.info {
    width: 100%;
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin-bottom: 5px;
}

.level_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.level {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-left: 10px;
}

.lv_n_icon {
    width: 36px;
    height: 36px;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 58px;
}
