.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.main {
    width: 100%;
    height: 100%;
}

.title_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 6px;
    user-select: none;
}

.title_item:hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.collapsibleContent {
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.item_collapsed {
    max-height: 0;
}

.item_expanded {
    max-height: 300px;
}

.expert_butn_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 40px);
    height: 100%;
    cursor: pointer;
}

.img {
    width: 24px;
    height: 24px;
    margin-right: 6px;
}

.text {
    width: calc(100% - 30px - 12px);
    color: #e7e7e7;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.shrink_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.shrink_img {
    width: 11px;
    height: 6px;
}

.model_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 4列，等宽 */
    grid-template-rows: repeat(2, auto); /* 2行，自动高度 */
    gap: 8px 10px; /* 上下间距8px，左右间距16px */
    padding: 10px 15px;
    width: 100%;
    height: 103px;
    box-sizing: border-box;
}

.item {
    width: 35px;
    height: 35px;
    border: 1px solid #55575c;
    border-radius: 6px;
    background-color: #32353a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.item_plus {
    border-color: #c1c1c1;
    transition: background-color .2s ease;
}

.item_plus:hover {
    background-color: #55575c;
}

.item_plus:active {
    background-color: #32353a;
}

.item_plus_img {

}

.item_lock {
    cursor: not-allowed;
}

.item_lock_img {

}

.item_active {
    background-color: #fff;
}

.item_img {
    width: 28px;
    height: 28px;
}