.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.none_img {
    width: 217px;
    height: 216px;
    margin-bottom: 42px;
}

.none_text {
    font-size: 24px;
    color: #101417;
    margin-bottom: 24px;
}

.none_explain {
    font-size: 16px;
    color: #414648;
    margin-bottom: 24px;
}

.create_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 58px;
    border-radius: 6px;
    color: #fff;
    background-color: #17A8B5;
    cursor: pointer;
}
