
.container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    /* width: 90vw;
    height: 90vh;
    transform: translateY(5vh); */
}

.img_limit {
    border-radius: 6px;
    transition: opacity 0.3s ease-in-out;
}

.img_limit,
.video_limit {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%; */
}

.video_limit {
    /* width: 100%;
    height: 100%; */
}

.close_button {
    display: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../../../../statics/modal_image_close.png') no-repeat center / 100% 100%;
}

.img_limit:hover ~ .close_button {
    display: block;
}

.close_button:hover {
    display: block;
}