.modal_container {
    width: 560px;
    height: 564px;
}

.modal_container :global(.ant-modal-content) {
    padding: 0 0 16px 0 !important;
}

/* .modal_container :global(.ant-modal-close:hover) {
    background-color: #fff;
} */

.main {
    width: 100%;
    height: 548px;
    padding: 65px 52px 0 52px;
}

.header,
.section,
.section2 {
    margin-bottom: 20px;
}

.header_title {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #1F1F1F;
    text-align: center;
}

.header_explain {
    font-size: 14px;
    color: #1f1f1f;
}

.section_title,
.section2_title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
    color: #1f1f1f;
}

.section2_title {
    margin-bottom: 5px;
}

.custom_textarea:hover,
.custom_textarea:focus-within,
.custom_textarea:focus {
    border-color: #17a8b5;
}

/* 自定义滚动条 */
.custom_textarea::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.custom_textarea::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.custom_textarea::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
    cursor: default;
}

@keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-2px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(2px);
    }
}

.feel_butn_box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 0 65px;
}

.feel_butn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.feel_butn:not(.selected):hover {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.feel_butn_img {
    width: 36px;
    height: 36px;
}

.hook_img {
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 32px;
    height: 32px;
}

.footer {
    width: 100%;
    height: 48px;
}

.close_butn {
    width: 24px;
    height: 24px;
    background: url('../../statics/modal_image_close.png') no-repeat center / 100% 100%;
}