.main {
    width: 100%;
    height: 100%;
}

.header {
    height: 35px;
    margin-bottom: 25px;
}

.title {
    font-size: 30px;
}

.container {
    width: 100%;
    min-height: calc(100% - 60px);
    padding: 20px;
    border-radius: 6px;
    background-color: #ffffff;
}

.add_box {
    width: 100%;
    height: 50px;
}

.table_box {
    width: 100%;
}