.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.container::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.container::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.container::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.section,
.section_error {
    width: 60%;
    margin-top: 50px;
}

.section_error {
    font-size: 22px;
}

.div_line {
    width: 100%;
    padding: 1px;
    border-bottom: 1px solid #D9D9D9;
    margin: 60px 0;
}