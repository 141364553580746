.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px 0 15px;
    cursor: pointer;
    border-radius: 6px;
}

.main:hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.username {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 32px - 14px - 14px);
    height: 100%;
    padding-left: 10px;
    font-size: 14px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logout_img {
    width: 14px;
    height: 14px;
}

.logout_butn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.logout_red_img {
    width: 16px;
    height: 16px;
}

.logout_text {
    padding-left: 5px;
    font-size: 14px;
    color: #E31A1A;
}

.userinfo_butn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.userinfo_text_box {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}

.userinfo_username {
    color: #605D64;
}

.userinfo_email {
    color: #7E8B9E;
}

.userinfo_setting {
    width: 13px;
    height: 14px;
}


:global(.userinfo_dropdown .ant-dropdown-menu .ant-dropdown-menu-item) {
    padding: 0;
}

.logout_butn,
.userinfo_butn {
    padding: 5px 12px;
}

.admin_text_box {
    color: #7e8b9e;
}