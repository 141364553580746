.main {
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.container_left,
.container_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    height: 60%;
}

.container_right {
    width: 50%;
}

.container_middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50%;
}

.container_item_header {
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 25px;
}

.container_left_content,
.container_right_content {
    width: 100%;
    height: 90%;
    padding: 10px;
    border: 2px solid #ccc;
}

.container_right_content {
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.container_right_content::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.container_right_content::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.container_right_content::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.item_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 160px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 6px;
}

.item_percent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    font-size: 25px;
    font-weight: bold;
}

.item_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68%;
    height: 100%;
}

.item_img {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

.item_text_box {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.item_text_name,
.item_text_category,
.item_text_brand {
    width: 100%;
    text-align: left;
}

.check_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.search_type_box {
    width: 100%;
    height: 30%;
    padding: 10px;
    margin-bottom: 20px;
}

.sort_type_box,
.algorithm_type_box {
    margin-bottom: 20px;
}