.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 178px;
    height: 50px;
    padding: 0 10px;
    border: 1px solid #ebedf0;
    border-radius: 6px;
    transition: 0.5s;
}

.container:hover {
    cursor: pointer;
    background-color: #e4f0f2;
}

.container:active {
    background-color: #74bdc6;
}

.img {
    width: 32px;
    height: 32px;
}

.text {
    font-size: 14px;
    color: #7E8B9E;
    transition: 0.5s;
}

.container:active .text {
    color: #f2f8f9;
}