.image_container {
    position: relative;
    width: 100%;
    height: 100%;
}

.img_box {
    position: absolute;
    bottom: calc(100vh - 640px);
    left: 50%;
    transform: translateX(-50%);
}

.move_up_animation {
    width: 102px;
    height: 102px;
    animation: moveUp 1s linear forwards; /* 动画名称、持续时间和让动画保持结束状态 */
}

@keyframes moveUp {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-200px);
    }
}
