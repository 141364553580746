.container {

}

.main {
    width: 100%;
    height: calc(100% - 217px);
    padding-bottom: 20px;
}

.filter_box {
    /* margin-top: 5px; */
}

.filter_title {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 20px;
    color: #4f4f4f;
    margin-bottom: 16px;
}

.filter_box:not(:first-child) .filter_title {
    margin-top: 36px;
}

.filter_hint {
    margin: 16px 0;
    font-size: 14px;
    font-family: 'PingFangSCMedium';
    color: #7e8b9e;
    cursor: default;
}

.list_box {

}

.list_item_box {
    border: 1px solid #cbd1d8;
    transition: box-shadow 0.5s;
}

.list_item_box:hover {
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
}

.list_item {
    width: 100%;
    height: 56px;
    padding: 0 15px;
    overflow: hidden; /* 隐藏溢出的内容 */
    transition: height 0.4s;
}

.list_item_open {
    height: 112px;
    transition: height 0.4s;
}

.list_item_box:not(:last-child) {
    border-bottom: none;
}

.list_box .list_item_box:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.list_box .list_item_box:not(:first-child):last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.item_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
}

.vector_box,
.vector_open_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.vector_open_box {
    justify-content: center;
}

/* .vector_box:hover {
    background-color: #c6e0e4;
}

.vector_box:active {
    background-color: #17a8b5;
} */

.vector_drag {
    width: 17px;
    height: 6px;
}

.vector_message {
    width: 14px;
    height: 14px;
}

.vector_open {
    width: 11px;
    height: 6px;
}

.abstract_box {
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.abstract {
    width: 100%;
    height: 100%;
    font-family: 'PingFangSCMedium';
    font-size: 16px;
    color: #4f4f4f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.tool_box {
    display: flex;
    justify-content: flex-end;
    width: 24%;
}

.tool_enter,
.tool_delete,
.tool_collect,
.tool_more {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 32px;
    transition: background 0.5s, box-shadow 0.5s;
    margin-right: 6px;
}

.tool_enter {
    background: url('../../../../statics/collect_edit.png') no-repeat center / 100% 100%;
}

/* 隐藏伪元素用来预加载悬停背景图 */
.tool_enter::before {
    content: "";
    background: url('../../../../statics/collect_edit_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_enter::after {
    content: "";
    background: url('../../../../statics/collect_edit_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_enter:hover {
    background: url('../../../../statics/collect_edit_hover.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.tool_enter:active {
    background: url('../../../../statics/collect_edit_active.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.tool_delete {
    background: url('../../../../statics/collect_delete.png') no-repeat center / 100% 100%;
}

/* 隐藏伪元素用来预加载悬停背景图 */
.tool_delete::before {
    content: "";
    background: url('../../../../statics/collect_delete_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_delete::after {
    content: "";
    background: url('../../../../statics/collect_delete_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_delete:hover {
    background: url('../../../../statics/collect_delete_hover.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.tool_delete:active {
    background: url('../../../../statics/collect_delete_active.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.tool_more {
    background: url('../../../../statics/collect_more.png') no-repeat center / 100% 100%;
}

/* 隐藏伪元素用来预加载悬停背景图 */
.tool_more::before {
    content: "";
    background: url('../../../../statics/collect_more_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_more::after {
    content: "";
    background: url('../../../../statics/collect_more_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_more:hover {
    background: url('../../../../statics/collect_more_hover.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.tool_more:active {
    background: url('../../../../statics/collect_more_active.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.time {
    width: 100%;
    text-align: end;
    font-size: 14px;
    font-family: 'PingFangSCMedium';
    color: #7e8b9e;
}

.item_bottom {
    display: flex;
    width: 100%;
    height: 56px;
    border-top: 1px solid #cbd1d8;
}

.model_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
}

.model_img {
    width: 28px;
    height: 28px;
    margin-right: 16px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #4f4f4f;
}

.model_name {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #4f4f4f;
}

.num_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    height: 100%;
}

.text_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 110px; */
}

.text_box:not(:last-child) {
    margin-right: 25px;
}

.text_title {
    font-family: 'PingFangSCMedium';
    font-size: 14px;
    color: #7e8b9e;
    margin-right: 10px;
}

.text_img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.num {

}

.popover_box {

}

.popover_item {
    width: 140px;
    height: 40px;
}

.popover_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.5s;
}

.popover_item:hover {
    background-color: #dfdfdf;
}

.popover_item:active {
    background-color: #cdcdcd;
}

.popover_butn_img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.popover_item_text {
    font-size: 14px;
}

:global(.custom_popover_collect .ant-popover-content .ant-popover-inner) {
    padding: 5px;
}

.tool_collect {
    background: url('../../../../statics/collect_mark.png') no-repeat center / 100% 100%;
}

/* 隐藏伪元素用来预加载悬停背景图 */
.tool_collect::before {
    content: "";
    background: url('../../../../statics/collect_mark_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_collect::after {
    content: "";
    background: url('../../../../statics/collect_mark_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.tool_collect:hover {
    background: url('../../../../statics/collect_mark_hover.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.tool_collect:active {
    background: url('../../../../statics/collect_mark_active.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.ask_abstract_box {
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* .ask_abstract {
    width: 100%;
    height: 100%;
    font-family: 'PingFangSCMedium';
    font-size: 16px;
    color: #4f4f4f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
} */

.ask_list_item {
    width: 100%;
    /* min-height: 56px; */
    padding: 15px;
    /* overflow: hidden; */
    overflow: visible;
    transition: height 0.4s;
}

.ask_item_top {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.ask_item_top_open {
    align-items: flex-start;
}

.ask_vector_box,
.ask_vector_open_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 32px;
    height: 42px;
    cursor: pointer;
    transition: background-color 0.3s;
    flex-shrink: 0; /* 防止被压缩 */
    padding-top: 10px;
}

.ask_model_img {
    width: 28px;
    height: 28px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #4f4f4f;
    flex-shrink: 0; /* 防止被压缩 */
}

.ask_vector_open_box {
    justify-content: flex-end;
    align-items: flex-start;
}

.ask_tool_box {
    display: flex;
    justify-content: flex-end;
    width: 24%;
    flex-shrink: 0; /* 防止被压缩 */
    padding-top: 10px;
}

.ask_abstract {
    max-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 限制显示两行 */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    flex-grow: 1;
    transition: max-height .3s ease;
}

.ask_abstract.expanded {
  -webkit-line-clamp: unset; /* 取消行数限制 */
  max-height: none;
    white-space: normal;
}

.ask_abstract.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 限制显示两行 */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* 超出部分显示省略号 */
}