
.content {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 10px;
    padding-bottom: 15px;
    padding-right: 70px;
}
.mobile_content {
    padding-right: 20px!important;
    padding-left: 20px!important;
    -webkit-overflow-scrolling : touch;
}
.mobile_content .item_box {
    width: 100%;
    padding-right: 0;
}
.mobile_content .message_times {
    display: none!important;
}
/* 自定义移动端滚动条 */
.mobile_content::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 4px!important;
    height: 4px!important;
}

.mobile_content::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.mobile_content::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}
/* 自定义滚动条 */
.content::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.content::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.content::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.item_box {
    width: 60%;
    margin: 0 auto;
    padding-right: 50px;
    padding-bottom: 16px;
}

::selection {
    background-color: #cce2ff;
    color: rgba(0,0,0,.87);
}

.message_times {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 27px;
    padding-left: 63px;
    margin-top: 32px;
    font-size: 16px;
}

.message_times_text {
    display: inline-block;
    width: auto;
    height: 100%;
    padding: 0 5px;
    border-radius: 6px;
    color: #fff;
    background-color: #cbd1d8;
    line-height: 27px;
}

.message_times_today_text {
    width: 100%;
    height: 27px;
    margin: 0 !important;
}
