.main {
    position: absolute;
    right: 0;
    bottom: 19px;
}

.list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.item {
    margin-right: 12px;
}

.item:last-child {
    margin-right: 20px;
}
