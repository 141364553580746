/* styles.css */
@keyframes slideUpAndFade {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOutAndFade {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.exp_container {
    display: inline-block;
    position: relative;
    height: 12px;
    line-height: 12px;
}

.exp_value {
    position: absolute;
    color: #c1c1c1;
}

.slide_up_and_fade {
    animation: slideUpAndFade 0.5s forwards ease-in-out;
}

.slide_out_and_fade {
    animation: slideOutAndFade 0.5s forwards ease-in-out;
}

.total {

}