/* Medium */
@font-face {
    font-family: 'PingFangSCMedium';
    font-weight: 500;
    src: url('../fonts/PingFang Medium.ttf') format('truetype');
}

/* Regular */
@font-face {
    font-family: 'PingFangSCRegular';
    font-weight: 500;
    src: url('../fonts/PingFang Regular.ttf') format('truetype');
}
