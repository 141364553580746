.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 73px 0;
    cursor: default;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 100%;
}

.container_left,
.container_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 48%;
    height: 100%;
}

.personal_area {
    width: 100%;
    /* height: calc(410px + 30px + 16px); */
    height: calc(100% - 260px);
}

.purchased_model_area {
    width: 100%;
    height: calc(181px + 30px + 16px);
}

.shopping_area {
    width: 100%;
    height: 100%;
}

.area_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-bottom: 15px;
}

.header_img {
    width: 28px;
    height: 25px;
    margin-right: 8px;
}

.header_title {
    font-size: 20px;
    color: #101417;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
}

.more_box {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    cursor: pointer;
    user-select: none;
}

.more_text {
    font-size: 14px;
    font-weight: bold;
    color: #17A8B5;
    margin-right: 5px;
}

.more_img {
    width: 12px;
    height: 13px;
}

.personal_box,
.purchased_model_box,
.shopping_model_box {
    width: 100%;
    height: calc(100% - 46px);
    padding: 20px;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
}

.personal_box {
    padding: 30px 50px 15px;
}

.purchased_model_box {

}

.shopping_model_box {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.shopping_model_box::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.shopping_model_box::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.shopping_model_box::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.model_box {
    width: 100%;
    height: 59px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.model {
    width: 59px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
}

.model_unactive {
    background-color: #ebebec;
}

.model_img {
    width: 45px;
    height: 45px;
}

.shopping_model_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 93px;
    padding: 11px 7px;
    border: 1px solid #D9D9D9;
    margin-bottom: 15px;
    border-radius: 12px;
    background-color: #f9f9f9;
}

.shopping_model_item:last-child {
    margin-bottom: 0;
}

.shopping_model_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    min-width: 51px;
    height: 51px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background-color: #FFF;
}

.shopping_model_img {
    width: 38px;
    height: 38px;
}

.shopping_model_text_box {
    flex-grow: 1;
    padding: 0 55px 0 14px;
}

.shopping_model_text_box_unowned {
    padding: 0 10px 0 14px;
}

.shopping_model_text_title {
    width: 100%;
    height: 28px;
    line-height: 28px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
}

.shopping_model_text_info {
    display: -webkit-box;        /* 创建一个弹性盒子 */
    -webkit-box-orient: vertical; /* 设置盒子的子元素排列方式为垂直 */
    -webkit-line-clamp: 2;       /* 限制显示两行 */
    overflow: hidden;            /* 隐藏超出内容 */
    text-overflow: ellipsis;     /* 使用省略号表示超出的文本 */
    color: #414648;
    font-size: 12px;
    line-height: 20px;
}

.shopping_model_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    border-radius: 6px;
    font-size: 11px;
}

.shopping_model_owned {
    width: 83px;
    min-width: 83px;
    color: #74bdc6;
    background-color: #e4f0f2;
    border: 1px solid #74bdc6;
    border-bottom-width: 2px;
}

.shopping_model_purchase {
    width: 128px;
    min-width: 128px;
    color: #EABC4E;
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-bottom-width: 2px;
}

.model_status_img {
    margin-right: 5px;
}

.personal_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90px;
    margin-bottom: 15px;
}

.personal_info_img {
    width: 90px;
    height: 90px;
    margin-right: 20px;
}

.personal_info_text_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 10px);
    height: 100%;
}

.welcome_title {
    font-family: 'PingFangSCMedium';
    font-size: 24px;
    color: #101417;
    font-weight: bold;
}

.welcome_info {
    font-family: 'PingFangSCMedium';
    font-size: 15px;
    color: #323A45;
    font-weight: bold;
}

.create_butn_box {
    width: 100%;
    height: 58px;
    margin-bottom: 25px;
}

/* .create_butn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 58px;
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    background-color: #17a8b5;
    background: url('../../../../statics/create_butn.png') no-repeat center / 100% 100%;
    transition: background 0.5s, box-shadow 0.5s;
    transform: translateZ(0);
    margin-bottom: 25px;
}

.create_butn::before {
    content: "";
    background: url('../../../../statics/create_butn_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.create_butn::after {
    content: "";
    background: url('../../../../statics/create_butn_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

.create_butn:hover {
    background: url('../../../../statics/create_butn_hover.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
}

.create_butn:active {
    background: url('../../../../statics/create_butn_active.png') no-repeat center / 100% 100%;
    box-shadow:0px 5px 10px #ddd;
} */

.personal_grade_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 72px;
}

.first_personal_grade_box {
    margin-bottom: 15px;
}

.personal_grade_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 45%;
    height: 100%;
    padding-left: 20px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
}

.grade_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    max-width: 36px;
    max-height: 36px;
    margin-right: 10px;
}

.grade_img {
    max-width: 36px;
    max-height: 36px;
}

.grade_text_box {

}

.grade_text_title {
    font-size: 15px;
    color: #C1C1C1;
    line-height: 20px;
    margin-bottom: 5px;
}

.grade_text_value {
    font-size: 15px;
    color: #323a45;
    line-height: 20px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
}