
.container {
    width: 100%;
    height: auto;
    padding: 27px 16px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    margin-bottom: 16px;
}

.container__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 28px;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 10px;
}

.container__header_title {
    color: #0F051D;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
    user-select: none;
}

.container__header_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    cursor: pointer;
}

.container__header_icon:hover {
    background-color: #f9f9f9;
    border-radius: 6px;
}

.container__collapse :global(.ant-collapse-header) {
    display: none !important;
}

.container__collapse:global(.ant-collapse),
.container__collapse :global(.ant-collapse-content) {
    border: none !important;
    background-color: #fff !important;
}

.container__collapse :global(.ant-collapse-content-box) {
    padding: 0 !important;
}
