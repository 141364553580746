.container {
    width: 100%;
    height: calc(43px + 16px + 33px);
}

.top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    height: 43px;
    margin-bottom: 16px;
}

.bottom {
    width: 100%;
    height: 33px;
}

.search_box {
    position: relative;
    width: 40%;
    height: 100%;
}

.search {
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px 30px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    font-size: 14px;
}

.search_icon {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 11px;
    height: 12px;
}

.section {
    width: 60%;
    height: 100%;
}

.section_box {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.section_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    margin-right: 8px;
    border-radius: 6px;
    color: #4f4f4f;
    background-color: transparent;
    cursor: pointer;
}

.section_butn:hover {
    background-color: #ccc;
}

.section_butn_active,
.section_butn_active:hover {
    color: #fff;
    background-color: #17a8b5;
}

.content_radio {
    display: flex;
    width: 60%;
    height: 100%;
}

.content_radio_butn {
    cursor: pointer;
    user-select: none;
}

.content_radio_text {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 16px;
    color: #323a45;
    line-height: 24px;
    padding: 3px 16px;
    margin-right: 8px;
    user-select: none;
}

.content_radio_butn_active .underline {
    border: 2px solid #17a8b5;
    border-radius: 5px;
}