.main {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal_slice :global(.ant-modal-content) {
    width: 650px;
}

.modal_content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 400px;
}

.content_title {
    width: 20%;
    height: 100%;
    padding-top: 20px;
}

.content_box {
    width: 80%;
    height: 100%;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 6px;
    background-color: #f8f8fa;
}

/* 自定义滚动条 */
.content_box::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.content_box::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.content_box::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.slice_card_content {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}