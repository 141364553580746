.header {
    margin-bottom: 25px;
}

.title {
    font-size: 30px;
}

.chart_box_main {

}

.chart_box_header {

}

.chart_box_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
}

.table_box_item {
    width: 100%;
}