.main {
    width: 100%;
    height: 560px;
    padding: 30px 0;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 38px;
    font-weight: bold;
    color: #262626;
}

.section {
    display: flex;
    width: 100%;
    height: 230px;
    padding-top: 15px;
    border-bottom: 1px solid #CBD1D8;
}

.section_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 435px);
    height: 100%;
    padding-right: 24px;
}

.avatar_box {
    position: relative;
    width: 135px;
    height: 135px;
}

.change_avatar {
    position: absolute;
    width: 32px;
    height: 32px;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.avatar {
    width: 135px;
    height: 135px;
    border-radius: 50%;
}

.username,
.email {
    font-size: 16px;
    color: #000;
}

.section_right {
    width: 435px;
    height: 100%;
}

.form_item,
.form_item_name {
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
}

.form_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.form_item_name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.firstname_box,
.lastname_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 208px;
    height: 70px;
}

.item_title {
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.func_butn {
    width: 80px !important;
}

.radio_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;
    height: 100%;
    padding: 2px;
    border-radius: 6px;
    background-color: #81969d;
}

.radio_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    font-size: 14px;
    color: #cbd1d8;
    /* cursor: pointer; */
    cursor: not-allowed;
}

.radio_item_active {
    border-radius: 4px;
    font-weight: bold;
    color: #17a8b5;
    background-color: #fff;
}

.item_size {
    padding: 0 50px;
    flex: 1;
    text-align: right;
}

.firstname_input,
.lastname_input,
.nickname_input {
    width: 100%;
    height: 43px;
}

.firstname_input:hover,
.firstname_input:focus-within,
.firstname_input:focus,
.lastname_input:hover,
.lastname_input:focus-within,
.lastname_input:focus,
.nickname_input:hover,
.nickname_input:focus-within,
.nickname_input:focus {
    border-color: #17a8b5;
}

.language_select {
    width: 100%;
    height: 43px;
    border-radius: 6px;

}

.section2 {
    display: flex;
    width: 100%;
    height: 130px;
    margin: 30px 0;
}

.section2_right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.func_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 50px;
}

.delete_account {
    padding: 4px 15px;
    border-radius: 6px;
    cursor: not-allowed;
    user-select: none;
}

.delete_account:hover {
    background-color: #f5f5f5;
}
