.main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 1030;
    background-color: rgba(0, 0, 0, 0.6);
}

.container {
    position: relative;
    width: 90vw;
    height: 90vh;
    margin: auto;
    transform: translateY(5vh);
}

.img_limit,
.video_limit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
}

.video_limit {
    width: 100%;
    height: 100%;
}
