.item_title {
    color: #111111;
    font-size: 20px;
    font-weight: bold;
}

.item_description {
    color: #111111;
    font-size: 14px;
}

.item_indicators {
    display: flex;
    margin-bottom: 12px;
}

.item_indicators_circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 6px;
    background-color: #dcdee6;
    cursor: pointer;
}

.item_indicators_circle_active {
    background-color: #17a8b5;
}

:global(.custom_tour.ant-tour) {
    width: 288px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) ;
    /* transition: all 0.3s; */
}

:global(.custom_tour.ant-tour.custom_tour_tour1) {
    animation: fadeInModal1 1.0s ease-in-out forwards;
}

:global(.custom_tour.ant-tour.custom_tour_tour2) {
    animation: fadeInModal2 1.0s ease-in-out forwards;
}

:global(.custom_tour.ant-tour.custom_tour_tour3) {
    animation: fadeInModal3 1.0s ease-in-out forwards;
}

:global(.custom_tour.ant-tour.custom_tour_tour4) {
    animation: fadeInModal4 1.0s ease-in-out forwards;
}

:global(.custom_tour.ant-tour.custom_tour_tour5) {
    animation: fadeInModal5 1.0s ease-in-out forwards;
}

:global(.custom_tour.ant-tour.custom_tour_tour6) {
    animation: fadeInModal6 1.0s ease-in-out forwards;
}

@keyframes fadeInModal1 {
    0% {
        opacity: 0; /* 初始状态为完全透明 */
    }
    100% {
        opacity: 1; /* 结束状态为完全不透明 */
    }
}

@keyframes fadeInModal2 {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeInModal3 {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeInModal4 {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeInModal5 {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeInModal6 {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

:global(.custom_tour.ant-tour .ant-tour-inner) {
    padding: 16px 24px 12px !important;
}

:global(.custom_tour.ant-tour .ant-tour-header),
:global(.custom_tour.ant-tour .ant-tour-description) {
    padding: 0;
}

:global(.custom_tour.ant-tour .ant-tour-header) {
    margin-bottom: 16px;
}

:global(.custom_tour.ant-tour .ant-tour-description) {
    margin-bottom: 24px;
}

:global(.custom_tour.ant-tour .ant-tour-footer) {
    display: flex;
    flex-direction: column;
    padding: 0;
}

:global(.custom_tour.ant-tour .ant-tour-buttons) {
    width: 100%;
    height: 38px;
    margin: 0;
}

:global(.custom_tour.ant-tour .ant-tour-buttons .ant-btn) {
    background-color: transparent;
}

:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_box),
:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_done_box) {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    margin-inline-start: 0 !important;
    box-shadow: none;
    transition: background 0.5s;
}
/*
:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_box) {
    background: url('../../../../statics/tour_next_butn.png') no-repeat center / 100% 100%;
}

:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_done_box) {
    background: url('../../../../statics/update_done_butn.png') no-repeat center / 100% 100%;
}

:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_done_box::before) {
    content: "";
    background: url('../../../../statics/update_done_butn_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_done_box::after) {
    content: "";
    background: url('../../../../statics/update_done_butn_active.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_done_box:hover) {
    background: url('../../../../statics/update_done_butn_hover.png') no-repeat center / 100% 100%;
}

:global(.custom_tour.ant-tour .ant-tour-buttons .custom_next_butn_done_box:active) {
    background: url('../../../../statics/update_done_butn_active.png') no-repeat center / 100% 100%;
} */

.next_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.next_butn_text {
    letter-spacing: 0;
}

.next_butn_icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background: url('../../../../statics/tour_next_butn_arrow.png') no-repeat center / 100% 100%;
}

:global(.custom_tour.ant-tour .ant-btn),
:global(.custom_tour.ant-tour .ant-wave),
:global(.custom_tour.ant-tour .ant-wave-motion-appear) {
    animation: none !important;
    transition: none;
}

:global(.custom_tour .ant-tour-placeholder-animated) {
    rx: 6px;
}