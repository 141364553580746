.modal_container :global(.ant-modal-content) {
    width: 100%;
    height: 820px;
    border-radius: 20px;
}

.modal_container :global(.ant-modal-body) {
    width: 100%;
    height: 95%;
}

.modal_container .footer :global(.ant-form-item) {
    margin-bottom: 0;
}

.left_content :global(.ant-form-item) {
    margin-bottom: 10px;
}

.main {
    display: flex;
    width: 100%;
    height: calc(100% - 40px);
}

.main_left,
.main_right {
    width: 50%;
    height: 100%;
    padding: 0 20px;
    border: 1px solid #ccc;
}

.left_header,
.right_header {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    text-align: center;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 40px;
}

.left_header {

}

.right_header {

}

/* 前台信息 */
.right_header_img_box {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.right_header_img {
    width: 55px;
    height: 55px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.right_header_butn {
    margin-right: 10px;
}

.right_header_text_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    width: 60%;
}

.name_input {
    width: 60%;
}

.right_header_text {
    margin-bottom: 10px;
}

.introduce_box,
.example_box {
    width: 100%;
    height: 150px;
    padding: 10px 0;
}

.introduce_title,
.example_title {
    padding: 0 0 5px 10px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
    text-align: left;
}

.introduce_text,
.example_text {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9D9D9;
    background-color: #f9f9f9;
    border-radius: 12px;
    font-size: 12px;
    color: #414648;
}

.change_avatar {
    position: absolute;
    top: -16px;
    right: -16px;
    cursor: pointer;
}

.custom_textarea {
    background-color: #f9f9f9;
}