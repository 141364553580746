.bubble {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #FDE0D0;
    background-color: #fde0d0;
    border-radius: 6px;
    transition: all 2s ease-in-out;
    transform-origin: center bottom;
    cursor: default;
}

.appear {
    animation: bubbleIn 0.5s forwards;
}

.disappear {
    animation: bubbleOut 2s forwards;
}

.img {
    width: 18px;
    height: 18px;
}

.text {
    font-size: 14px;
    color: #E31A1A;
}

@keyframes bubbleIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bubbleOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
