.custom_modal :global(.ant-modal-content) {
    width: 100%;
    height: 580px;
    border-radius: 16px;
}

.main {
    width: 100%;
    height: 500px;
    padding-top: 40px;
}

.title {
    width: 100%;
    height: 46px;
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    color: #262626;
    line-height: 46px;
}

.butn_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50%;
    margin-top: 100px;
}

.butn_box {
    width: 65%;
    height: 90px;
}

.butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 6px;
}

.butn_en {
    background: url('../../../../statics/qywx_jieen.png') no-repeat center / 100% 100%;
}

.butn_tu {
    background: url('../../../../statics/qywx_jietu.png') no-repeat center / 100% 100%;
}
