.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.sidebar {
    width: 280px;
    height: 100%;
    overflow-x: hidden;
    transition: all 0.5s ease;
}

.hide_sidebar {
    /* display: none; */
    width: 0;
    overflow-x: hidden;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 280px);
    height: 100%;
    transition: all 0.5s ease;
}

.content_zoom {
    width: 100%;
}

.side_arrow {
    position: absolute;
    left: 0;
    top: calc(50% - 11px);
    width: 22px;
    height: 22px;
}

.header {
    width: 100%;
    height: 53px;
}

@keyframes fadeInModel {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.ease_in_out {
    animation: fadeInModel 0.5s ease-in-out forwards;
}

.main {
    width: 100%;
    height: calc(100% - 53px);
}

.gpt_box {
    display: flex;
    width: 290px;
    height: 40px;
    border-radius: 6px;
}

.gpt_item {
    width: 145px;
    height: 100%;
    background-color: #fff;
    border: 1px solid #d8dde2;
    cursor: pointer;
}

.gpt_sel_done {
    background-color: #4f4f4f;
}

:global(.msg_search_result_container) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 16px;
}

:global(.msg_search_result_title_box) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 26px;
    font-size: 13px;
    color: #3568FF;
    margin-bottom: 10px;
}

:global(.msg_search_result_title) {
    padding-right: 10px;
    cursor: pointer;
}

:global(.msg_search_result_title_icon) {
    width: 11px;
    height: 6px;
    background: url('../../statics/msg_search_result_icon_launch.png') no-repeat center / 100% 100%;
    cursor: pointer;
}

:global(.msg_search_result_container_shrink .msg_search_result_title_icon) {
    background: url('../../statics/msg_search_result_icon_shrink.png') no-repeat center / 100% 100%;
}

:global(.msg_search_result_container_shrink .msg_search_result_item_box) {
    display: none;
}

:global(.msg_search_result_item_box) {
    width: 100%;
    display: none;
    display: flex;
    flex-wrap: wrap;
}

:global(.msg_search_result_item_link) {
    color: #323a45 !important;
    text-decoration: none;
    width: calc(100% - 30px);
}

:global(.msg_search_result_item_link:hover) {
    text-decoration: none !important;
}

:global(.msg_search_result_item) {
    width: 100%;
    display: flex;
}

:global(.msg_search_result_item_n) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-top: 2px;
    margin-right: 10px;
    background-color: #e4f0f2;
}

:global(.msg_search_result_item_info) {
    width: 100%;
    height: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;          /* 定义文本的行数 */
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    overflow: hidden;
    max-height: 50px;
    font-size: 14px;
}

:global(.msg_search_result_item_info_title) {
    font-weight: bold;
}

:global(.msg_search_result_item_info_text) {

}

:global(.msg_search_result_item_line) {
    width: 100%;
    height: 1px;
    border-top: 1px solid #e2e5e9;
    margin-top: 15px;
    margin-bottom: 15px;
}

.content_drag_area_modal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.content_drag_area_modal_none {
    align-items: center;
}

.content_drag_area_modal_none .content_drag_area_box {
    margin-top: 50px;
    margin-bottom: 0;
}

.content_drag_area_box {
    width: 60%;
    height: 170px;
    padding: 0 112px 0 55px;
    margin-bottom: 38px;
}

.content_drag_area {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid #C3C7CF;
    background-color: #ffffff;
    border-radius: 6px;
}

.content_drag_dashed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px dashed #C3C7CF;
    border-radius: 6px;
    background-color: #f2f8f9;
}

.content_drag_title {
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.content_drag_text {
    font-size: 16px;
    line-height: 20px;
}

.custome_drag_area_upload :global(.ant-upload-drag) {
    border: none;
}

.custome_drag_area_upload :global(.ant-upload) {
    padding: 0 !important;
}