.main {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin-bottom: 25px;
}

.title {
    font-size: 30px;
}

.container {
    width: 100%;
    height: calc(100% - 60px);
    min-height: calc(100% - 60px);
    padding: 20px;
    border-radius: 6px;
    background-color: #ffffff;
}

.container_header {
    width: 100%;
    height: 100px;
    font-size: 24px;
}

.container_main {
    width: 100%;
    height: calc(100% - 100px);
}

.container_main_show {
    width: 50%;
    height: calc(50% - 100px);
}

.container_null {
    padding: 20px;
    font-size: 20px;
    text-align: center;
}

.item_box {
    display: flex;
    align-items: center;
}