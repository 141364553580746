.container {
    /* width: 246px; */
    width: 100%;
}

.expert_butn_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    height: 100%;
    cursor: pointer;
}

.item_title {
    width: 100%;
    padding-left: 10px;
    color: #e7e7e7;
    font-family: 'PingFangSCRegular', sans-serif;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.shrink_img {
    width: 11px;
    height: 6px;
}